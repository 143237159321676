import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { getAllSalons, salonDelete } from "../../../redux/slice/salonSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import AddFAQModal from "./AddFAQModal";

export const Dummy = (count) => {
  const dummyFAQ = [];
  for (let i = 0; i < count; i++) {
    dummyFAQ.push({
      _id: i + 1,
      title: i + " 현장 결제는 포인트 사용이 불가한가요?",
      detail:
        i +
        `FAQ 답변에 대한 내용이 노출되는 자리입니다. 일정 길이 이후 FAQ 답변에 대한 내용이 노출되는 자리입니다. 일정 길이 이후로 FAQ 답변에 대한 내용이 노출되는 자리입니다. 일정 길이 이후`,
      type: i < 2 ? "예약" : i < 5 ? "결제" : "바텐더"
    });
  }
  return dummyFAQ;
};

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "detail",
    text: "내용"
  },
  {
    value: "type",
    text: "카테고리"
  }
];

export const SearchingWithSelect = (props) => {
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const {
    data,
    setData,
    type,
    typeSearch,
    serverSearchingType,
    serverSearchingValue,
    button
  } = props;

  const handleSearch = (value) => {
    let searchValue = search ? search : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingValue(searchValue);
    }
  };

  const handleSearchType = (value) => {
    let searchValue = searchType ? searchType : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingType(searchValue);
    }
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <select
            value={searchType}
            className="inputTypeSearch"
            style={{
              height: "40px",
              marginTop: "0",
              width: "130px",
              minWidth: "130px"
            }}
            placeholder="선택"
            onChange={
              button
                ? (e) => setSearchType(e.target.value)
                : (e) => handleSearchType(e.target.value)
            }
          >
            <option value={""} disabled>
              선택
            </option>
            {typeSearch.map((item) => (
              <option value={item.value} key={item.text}>
                {item.text}
              </option>
            ))}
          </select>
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            onChange={
              button
                ? (e) => setSearch(e.target.value)
                : (e) => handleSearch(e.target.value)
            }
          />
          <button
            className="py-1 bg-button-search"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            // onClick={() => handleOpenBookings(row)}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

export const FAQList = () => {
  const dummy = useMemo(() => Dummy(15), []);
  const [data, setData] = useState([]);

  const { salon } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search
    };
    dispatch(getAllSalons(payload));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(dummy);
  }, [dummy]);

  const noticeTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "카테고리",
      Cell: ({ row }) => <span>{row?.type}</span>
    },
    {
      Header: "질문 제목",
      Cell: ({ row }) => <span>{row?.title}</span>
    },
    {
      Header: "내용",
      tdClass: "colContentCoupon",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.detail}</span>
    },

    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <span className="">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleDelete(row?.id)}
          >
            삭제
          </button>
        </span>
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "FAQ 삭제",
        text: "해당 FAQ를 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "addFAQ", data: null }));
  };

  const handleOpenEdit = (row) => {
    dispatch(openDialog({ type: "addFAQ", data: row }));
  };

  return (
    <div className="userTable">
      <Title name="FAQ 등록" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect
            type={`server`}
            data={dummy}
            typeSearch={typeSearch}
            setData={setData}
            column={noticeTable}
            serverSearchingType={handleFilterData}
            serverSearchingValue={handleFilterDataType}
            button
          />
        </div>
        <Button
          className={`d-flex align-items-center bg-button p-10 text-white m10-bottom ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`등록`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={dummy}
          mapData={noticeTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
      {dialogue && dialogueType === "addFAQ" && (
        <AddFAQModal setData={setData} data={data} />
      )}
    </div>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { confirm, warning } from "../../../util/Alert";
import Title from "../../extras/Title";
import Button from "../../extras/Button";
import Searching, { SearchingWithSelect } from "../../extras/Searching";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import AddSalon from "./AddSalon";
import {
  activesalon,
  getAllSalons,
  salonDelete
} from "../../../redux/slice/salonSlice";

export const Dummy = (count) => {
  const dummyStore = [];
  for (let i = 0; i < count; i++) {
    dummyStore.push({
      _id: i + 1, // Assuming _id is a unique identifier
      name: "Dummy" + i,
      number: `number${i + 1}`,
      phone: i + "0111112222",
      numberTables: i + 2,
      youtube: "https://youtube.com/" + i,
      instagram: "https://www.instagram.com/" + i,
      naver: "https://blog.naver.com" + i,
      address: "address" + i,
      code: `code${i + 1}`,
      isActive: i < 5 ? true : false // Generate random age between 20 and 70
    });
  }
  return dummyStore;
};

const typeSearch = [
  {
    value: "",
    text: "선택"
  },
  {
    value: "name",
    text: "매장 이름"
  },
  {
    value: "number",
    text: "대표 번호"
  },
  {
    value: "code",
    text: "매장 코드"
  }
];

export const Salon = () => {
  const dummysalons = useMemo(() => Dummy(15), []);
  const [data, setData] = useState([]);

  const { salon } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search
    };
    dispatch(getAllSalons(payload));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(dummysalons);
  }, [dummysalons]);

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const handleInfo = (id) => {
    // navigate("/admin/salon/salonProfile", {
    //   state: {
    //     id
    //   }
    // });
  };

  const handleOpenMenu = (row) => {
    navigate("/admin/store/menu", {
      state: {
        row
      }
    });
  };

  const salonTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "매장 이름",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold cursor text-decoration-underline"
          onClick={() => handleInfo(row._id)}
        >
          {row?.name}
        </span>
      )
    },
    {
      Header: "대표 번호",
      Cell: ({ row }) => <span>{row?.number ? row?.number : "-"}</span>
    },
    {
      Header: "매장 코드",
      Cell: ({ row }) => <span className="">{row?.code}</span>
    },
    {
      Header: "활성화",
      body: "isActive",
      sorting: { type: "client" },
      Cell: ({ row }) => (
        <ToggleSwitch
          defaultValue={row?.isActive}
          // onClick={() => {
          //   dispatch(activesalon(row?._id));
          // }}
        />
      )
    },
    {
      Header: "예약 현황",
      body: "name",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenBookings(row)}
        >
          예약현황 보기
        </button>
      )
    },
    {
      Header: "메뉴",
      Cell: ({ row }) => (
        <span>
          <button
            className="py-1 text-decoration-underline"
            // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleOpenMenu(row)}
          >
            메뉴 보기
          </button>
        </span>
      )
    },
    {
      Header: "영업 시간",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleScheduleInfo(row)}
        >
          영업 시간 보기
        </button>
      )
    },
    {
      Header: "정산",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#CFF3FF", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => {
              handleEarning(row);
            }}
          >
            정산
          </button>
        </span>
      )
    },
    {
      Header: "이의 관리",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleOpenComplain(row)}
          >
            이의 관리
          </button>
        </span>
      )
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleAddSalon(row)}
          >
            수정
          </button>
        </span>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <span className="d-flex justify-content-center">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleDelete(row?._id)}
          >
            삭제
          </button>
        </span>
      )
    }
  ];

  const handleAddSalon = (row) => {
    navigate("/admin/store/addStore", {
      state: {
        row
      }
    });
  };

  const handleEarning = (row) => {
    navigate("/admin/store/settlement", {
      state: {
        row
      }
    });
  };

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "삭제 하시겠습니까?",
        text: "삭제 버튼 클릭 시 되돌릴 수 없습니다.",
        confirm: "삭제",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleScheduleInfo = (row) => {
    navigate("/admin/store/schedule", { state: { row } });
  };
  const handleOpenBookings = (row) => {
    navigate("/admin/store/bookings", { state: { data: row } });
  };

  const handleOpenComplain = (row) => {
    navigate("/admin/store/complains", { state: { data: row } });
  };

  return (
    <div className="userTable">
      <Title name="매장 관리" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect
            type={`server`}
            data={dummysalons}
            typeSearch={typeSearch}
            setData={setData}
            column={salonTable}
            serverSearchingType={handleFilterData}
            serverSearchingValue={handleFilterDataType}
            button
          />
        </div>
        <Button
          className={`bg-button-excel p-10 text-black font-bold m10-bottom ms-auto mt-auto`}
          text={`다운로드`}
          style={{ height: "40px" }}
          // bIcon={`fa-solid fa-user-plus`}
          // onClick={() => handleAddSalon()}
        />
        <Button
          className={`bg-button p-10 text-white m10-bottom mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`매장 추가`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleAddSalon()}
        />
      </div>
      <div>
        <Table
          data={dummysalons}
          mapData={salonTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
    </div>
  );
};

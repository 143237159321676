import React, { useEffect, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import moment from "moment";

const CSDetailModal = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [memo, setMemo] = useState("");

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-7 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">CS 상세 보기</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="row d-flex align-items-center">
                  <div className="col-6">
                    <ExInput
                      type={`text`}
                      label={"접수 일자"}
                      value={
                        dialogueData?.date &&
                        moment(dialogueData.date).format("YYYY-MM-DD")
                      }
                      disabled
                    />
                  </div>
                  <div className="inputData col-6">
                    <label>상태</label>
                    <div className="row mt-auto d-flex">
                      <Button
                        type={`submit`}
                        className={`text-white m10-left`}
                        style={{
                          backgroundColor: "#ED7D31",
                          height: "40px",
                          width: "120px"
                        }}
                        text={dialogueData?.status}
                        // onClick={(e) => handleSubmit(e)}
                      />
                      <Button
                        type={`submit`}
                        className={`text-white m10-left`}
                        style={{
                          backgroundColor: "#E7E6E6",
                          height: "40px",
                          width: "120px"
                        }}
                        text={`완료 처리`}
                        // onClick={(e) => handleSubmit(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <ExInput
                    type={`text`}
                    label={"회원 이름"}
                    value={dialogueData?.name}
                    disabled
                  />
                </div>
                <div className="col-4">
                  <ExInput
                    type={`text`}
                    label={"전화번호"}
                    value={dialogueData?.phone}
                    disabled
                  />
                </div>
                <div className="col-4">
                  <ExInput
                    type={`text`}
                    label={"답변 수신 이메일"}
                    value={dialogueData?.email}
                    disabled
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"문의 유형"}
                    value={dialogueData?.type}
                    disabled
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={dialogueData?.detail}
                    label={`문의 내용`}
                    row={3}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                    label={`CS 메모`}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`등록`}
                  // onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSDetailModal;

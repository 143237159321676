import React, { useEffect, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";

const fakeRecommendTag = [
  "#플레어바텐더",
  "#비밀보장",
  "#비밀친구",
  "#개인맞춤",
  "#위스키전문가",
  "#음료추천전문",
  "#음료추천전문",
  "#믹솔로지전문가",
  "#친절함"
];
const RecommendTag = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [hashTagList, setHashTagList] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (dialogueData) {
      setHashTagList(dialogueData.list);
    }
  }, [dialogueData]);

  const handleSelect = (value) => {
    if (selected.includes(value)) {
      const newSelected = [...selected].filter((item) => item !== value);
      setSelected(newSelected);
    } else {
      setSelected([...selected, value]);
    }
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">
                    추천 해시태그 설정 ({selected.length}/5개)
                  </h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div
                className="row align-items-start formBody px-2"
                style={{
                  columnGap: "20px"
                }}
              >
                {fakeRecommendTag.map((tag) => (
                  <div
                    className={`border rounded col-4 d-flex align-items-center cursor-pointer px-20 py-0 ${
                      selected.includes(tag) ? "bg-gray" : "bg-white"
                    }`}
                    style={{
                      height: "40px",
                      flexBasis: "calc(100%/3 - 20px)"
                    }}
                    key={tag}
                    onClick={() => handleSelect(tag)}
                  >
                    {tag}
                  </div>
                ))}
              </div>

              <div className="col-12">
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`저장`}
                    // onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendTag;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-dupe-keys */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "../../extras/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import {
  addService,
  updateService,
  getCatSubCat
} from "../../../redux/slice/serviceSlice";
import { getAllCategory } from "../../../redux/slice/categorySlice";
import { DangerRight } from "../../api/toastServices";
import { ExInput, SelectV2 } from "../../extras/Input";

const hours = [
  {
    name: 0,
    value: 0
  },
  {
    name: 1,
    value: 1
  },
  {
    name: 2,
    value: 2
  }
];

const minutes = new Array(31).fill(1).map((_, index) => ({
  value: index,
  name: index
}));

const hoursFull = new Array(25).fill(1).map((_, index) => ({
  value: index,
  name: index
}));

const minutesFull = new Array(60).fill(1).map((_, index) => ({
  value: index,
  name: index
}));

const ServiceDialogue = (props) => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const { subCat } = useSelector((state) => state.service);

  const [type, setType] = useState("set");
  const [order, setOrder] = useState("");
  const [day, setDay] = useState(false);
  const [hourStart, setHourStart] = useState();
  const [minuteStart, setMinuteStart] = useState();
  const [hourEnd, setHourEnd] = useState();
  const [minuteEnd, setMinuteEnd] = useState();

  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([
    {
      name: "",
      price: "",
      paymentAmount: "",
      basePrice: "",
      discount: "",
      availableReserTimeHour: "",
      availableReserTimeMinute: "",
      stock: ""
    }
  ]);
  const [optionSingle, setOptionSingle] = useState([
    {
      name: "",
      price: "",
      paymentAmount: "",
      basePrice: "",
      discount: "",
      availableReserTimeHour: "",
      availableReserTimeMinute: "",
      stock: ""
    }
  ]);
  const [name, setName] = useState();
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [mongoId, setMongoId] = useState();
  const [duration, setDuration] = useState();
  // const [price, setPrice] = useState();
  const [categoryy, setCategoryy] = useState();

  const [error, setError] = useState({
    name: "",
    categoryy: "",
    image: "",
    imagePath: "",
    duration: "",
    // price: "",
    categoryy: "",
    order: "",
    day: "",
    description: ""
  });

  const { category } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?._id);
      setName(dialogueData?.menuName);
      setDescription(dialogueData?.description);
      setOrder(dialogueData?.order);
      if (dialogueData?.type === "세트") {
        setType("set");
        setOptions([...dialogueData.options]);
      } else {
        setType("single");
        setOptionSingle([...dialogueData?.options]);
      }
      setImage(dialogueData?.image);
      setImagePath(dialogueData?.image);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    try {
      if (
        !name ||
        !image ||
        !imagePath ||
        !duration ||
        // !price ||
        duration <= 0 ||
        // price <= 0 ||
        !categoryy
      ) {
        let error = {};
        if (!name) error.name = "Name is Required";
        if (!duration) error.duration = "Duration is Required";
        // if (!price) error.price = "Price is Required";
        if (!categoryy) error.categoryy = "category is Required";
        // if (price <= 0) error.price = "Enter Correct price";
        if (duration <= 0) error.duration = "Enter Correct duration";
        if (!image?.length === 0 || !imagePath)
          error.image = "Name is Required";
        if (!imagePath) error.imagePath = "imagePath is Required";
        return setError({ ...error });
      } else {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("duration", duration);
        // formData.append("price", price);
        formData.append("image", image);
        formData.append("categoryId", categoryy);
        if (mongoId) {
          let payload = {
            formData,
            id: mongoId
          };
          dispatch(updateService(payload));
          dispatch(closeDialog());
        } else {
          dispatch(addService(formData));
          dispatch(closeDialog());
        }
        // dispatch(closeDialog());
      }
    } catch (err) {
      console.log("err.message", err);
    }
  };

  const handleImage = (e) => {
    if (!e.target.files) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "Image is Required"
      }));
    }
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
    setError((prevErrors) => ({
      ...prevErrors,
      image: ""
    }));
  };

  const handleAddOption = () => {
    setOptions([
      ...options,
      {
        name: "",
        price: "",
        paymentAmount: "",
        basePrice: "",
        discount: "",
        availableReserTimeHour: "",
        availableReserTimeMinute: "",
        stock: ""
      }
    ]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions([...newOptions]);
  };

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">메뉴 등록</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>

              <div className="row align-items-start formBody">
                {/* checkbox */}
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "5px", height: "40px" }}
                >
                  <ExInput
                    type={`radio`}
                    label={`세트`}
                    name={"type"}
                    value={`set`}
                    checked={type === "set" && true}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />

                  <ExInput
                    type={`radio`}
                    label={`단품`}
                    name={"type"}
                    value={`single`}
                    checked={type === "single" && true}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  />
                </div>
                {/* menu */}
                <div className="col-7 col-md-4">
                  <ExInput
                    type={`text`}
                    label={`메뉴 이름`}
                    name={"name"}
                    value={name}
                    newClass={"inputService"}
                    placeholder="이름을 입력하세요"
                    errorMessage={error?.name}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          name: ` Name is required`
                        });
                      } else {
                        return setError({
                          ...error,
                          name: ""
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-3 col-md-3">
                  <ExInput
                    type={`text`}
                    label={`표시순서`}
                    name={"order"}
                    newClass={"inputService"}
                    value={order}
                    errorMessage={error?.order}
                    onChange={(e) => {
                      setOrder(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          order: ` Order is required`
                        });
                      } else {
                        return setError({
                          ...error,
                          order: ""
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-2">
                  {/* <ExInput
                    type={`text`}
                    label={`낮 시간`}
                    name={"day"}
                    newClass={"inputService"}
                    value={day}
                    errorMessage={error?.day}
                    onChange={(e) => {
                      setDay(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          day: ` Day is required`
                        });
                      } else {
                        return setError({
                          ...error,
                          day: ""
                        });
                      }
                    }}
                  /> */}
                  <div className="d-flex flex-column align-items-center justify-content-center inpuData">
                    <label className="mt-2">낮 시간</label>
                    <input
                      type="checkbox"
                      style={{
                        height: "40px",
                        width: "40px",
                        marginTop: "5px",
                        borderColor: "#cfcfcf"
                      }}
                      checked={day}
                      onChange={(e) => setDay(e.target.checked)}
                    />
                  </div>
                </div>
                {day && (
                  <div className="row">
                    <div className="inputData col-12 col-md-8">
                      <label>낮 시간 설정</label>
                      <div
                        className="d-flex align-items-center"
                        style={{ marginTop: "4px" }}
                      >
                        <SelectV2
                          id={`hours`}
                          option={hoursFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                        />
                        <span className="px-1 text-nowrap">시</span>
                        <SelectV2
                          id={`minutes`}
                          option={minutesFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                        />
                        <span className="px-1">분</span>
                        <span className="px-4">&mdash;</span>
                        <SelectV2
                          id={`hours`}
                          option={hoursFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                        />
                        <span className="px-1 text-nowrap">시</span>
                        <SelectV2
                          id={`minutes`}
                          option={minutesFull}
                          className={`inputSelectTime`}
                          btnClass={`mt-0`}
                          angle={true}
                        />
                        <span className="px-1">분</span>
                      </div>
                    </div>
                  </div>
                )}
                {/* option single */}
                {type === "single" && (
                  <div className="row">
                    <div className="col-2 col-md-2">
                      <div className="d-flex align-items-center inputData">
                        <ExInput
                          type={`text`}
                          label={`가격`}
                          newClass={"inputService"}
                          value={optionSingle[0].price}
                          // errorMessage={error?.name}
                          onChange={(e) => {
                            let newOptions = [...optionSingle];
                            newOptions[0] = {
                              ...newOptions[0],
                              price: e.target.value
                            };
                            setOptionSingle(newOptions);
                          }}
                        />
                        <span
                          className="px-1 text-nowrap"
                          style={{ marginTop: "29px" }}
                        >
                          원
                        </span>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="d-flex align-items-center inputData">
                        <ExInput
                          type={`text`}
                          label={`정산금액`}
                          value={optionSingle[0].paymentAmount}
                          // errorMessage={error?.day}
                          onChange={(e) => {
                            let newOptions = [...optionSingle];
                            newOptions[0] = {
                              ...newOptions[0],
                              paymentAmount: e.target.value
                            };
                            setOptionSingle(newOptions);
                          }}
                        />
                        <span
                          className="px-1 text-nowrap"
                          style={{ marginTop: "29px" }}
                        >
                          원
                        </span>
                      </div>
                    </div>
                    <div className="col-2 col-md-2">
                      <div className="d-flex align-items-center inputData">
                        <ExInput
                          type={`text`}
                          label={`기준 가격`}
                          newClass={"inputService"}
                          value={optionSingle[0].basePrice}
                          // errorMessage={error?.name}
                          onChange={(e) => {
                            let newOptions = [...optionSingle];
                            newOptions[0] = {
                              ...newOptions[0],
                              basePrice: e.target.value
                            };
                            setOptionSingle(newOptions);
                          }}
                        />
                        <span
                          className="px-1 text-nowrap"
                          style={{ marginTop: "29px" }}
                        >
                          원
                        </span>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="d-flex align-items-center inputData">
                        <ExInput
                          type={`text`}
                          label={`할인율`}
                          newClass={"inputService"}
                          value={optionSingle[0].discount}
                          // errorMessage={error?.day}
                          onChange={(e) => {
                            let newOptions = [...optionSingle];
                            newOptions[0] = {
                              ...newOptions[0],
                              discount: e.target.value
                            };
                            setOptionSingle(newOptions);
                          }}
                        />
                        <span
                          className="px-1 text-nowrap"
                          style={{ marginTop: "29px" }}
                        >
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/* description */}
                <div className="row col-8">
                  <ExInput
                    type={`text`}
                    label={`메뉴 설명`}
                    name={"description"}
                    newClass={"inputService"}
                    placeholder="설명을 입력하세요"
                    value={description}
                    errorMessage={error?.description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          descripttion: `Descripttion is required`
                        });
                      } else {
                        return setError({
                          ...error,
                          descripttion: ""
                        });
                      }
                    }}
                  />
                </div>
                {/* options set */}
                {type === "set" &&
                  options.map((option, index) => (
                    <>
                      <div className="row">
                        <div className="col-7 col-md-3">
                          <ExInput
                            type={`text`}
                            label={`옵션 이름`}
                            newClass={"inputService"}
                            value={option.name}
                            placeholder="옵션 이름을 입력하세요"
                            // errorMessage={error?.name}
                            onChange={(e) => {
                              let newOptions = [...options];
                              newOptions[index] = {
                                ...newOptions[index],
                                name: e.target.value
                              };
                              setOptions(newOptions);
                            }}
                          />
                        </div>
                        <div className="col-2 col-md-2">
                          <div className="d-flex align-items-center inputData">
                            <ExInput
                              type={`text`}
                              label={`할인 가격
`}
                              newClass={"inputService"}
                              value={option.price}
                              // errorMessage={error?.name}
                              onChange={(e) => {
                                let newOptions = [...options];
                                newOptions[index] = {
                                  ...newOptions[index],
                                  price: e.target.value
                                };
                                setOptions(newOptions);
                              }}
                            />
                            <span
                              className="px-1 text-nowrap"
                              style={{ marginTop: "29px" }}
                            >
                              원
                            </span>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex align-items-center inputData">
                            <ExInput
                              type={`text`}
                              label={`정산금액`}
                              newClass={"inputService"}
                              value={option.paymentAmount}
                              // errorMessage={error?.day}
                              onChange={(e) => {
                                let newOptions = [...options];
                                newOptions[index] = {
                                  ...newOptions[index],
                                  paymentAmount: e.target.value
                                };
                                setOptions(newOptions);
                              }}
                            />
                            <span
                              className="px-1 text-nowrap"
                              style={{ marginTop: "29px" }}
                            >
                              원
                            </span>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 inputData">
                          <label>예약 가능 시간</label>
                          <div
                            className="d-flex align-items-center"
                            style={{ marginTop: "4px" }}
                          >
                            <SelectV2
                              id={`hours`}
                              option={hours}
                              className={`inputSelectTime`}
                              btnClass={`mt-0`}
                              angle={true}
                            />
                            <span className="px-1 text-nowrap">시간</span>
                            <SelectV2
                              id={`minutes`}
                              option={minutes}
                              className={`inputSelectTime`}
                              btnClass={`mt-0`}
                              angle={true}
                            />
                          </div>
                        </div>
                        <div className="col-2 col-md-1">
                          <div className="d-flex align-items-center inputData">
                            <ExInput
                              type={`text`}
                              label={`재고`}
                              newClass={"inputService"}
                              value={option.stock}
                              // errorMessage={error?.day}
                              onChange={(e) => {
                                let newOptions = [...options];
                                newOptions[index] = {
                                  ...newOptions[index],
                                  stock: e.target.value
                                };
                                setOptions(newOptions);
                              }}
                            />
                            <span
                              className="ms-1"
                              style={{ marginTop: "29px" }}
                            >
                              개
                            </span>
                          </div>
                        </div>
                        {options.length > 1 && (
                          <div
                            className="col-1 h-100 cursor-pointer"
                            style={{ marginTop: "35px" }}
                            onClick={() => handleRemoveOption(index)}
                          >
                            <div
                              className="rounded-2 d-flex align-items-center justify-content-center"
                              style={{
                                backgroundColor: "#fff1f1",
                                height: "40px"
                              }}
                            >
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="#f71845"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* row 2 */}
                      <div className="row">
                        <div className="col-7 col-md-3">&nbsp;</div>
                        <div className="col-2 col-md-2">
                          <div className="d-flex align-items-center inputData">
                            <ExInput
                              type={`text`}
                              label={`기준 가격`}
                              newClass={"inputService"}
                              value={option.basePrice}
                              // errorMessage={error?.name}
                              onChange={(e) => {
                                let newOptions = [...options];
                                newOptions[index] = {
                                  ...newOptions[index],
                                  basePrice: e.target.value
                                };
                                setOptions(newOptions);
                              }}
                            />
                            <span
                              className="px-1 text-nowrap"
                              style={{ marginTop: "29px" }}
                            >
                              원
                            </span>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex align-items-center inputData">
                            <ExInput
                              type={`text`}
                              label={`할인율`}
                              newClass={"inputService"}
                              value={option.discount}
                              // errorMessage={error?.day}
                              onChange={(e) => {
                                let newOptions = [...options];
                                newOptions[index] = {
                                  ...newOptions[index],
                                  discount: e.target.value
                                };
                                setOptions(newOptions);
                              }}
                            />
                            <span
                              className="px-1 text-nowrap"
                              style={{ marginTop: "29px" }}
                            >
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                {/* button add option set */}
                {type === "set" && (
                  <div className="d-flex col-12 flex-start">
                    <Button
                      className={`bg-gray text-white`}
                      text={`옵션 추가`}
                      type={`button`}
                      style={{ height: "40px" }}
                      onClick={handleAddOption}
                    />
                  </div>
                )}
                {/* stock single */}
                {type === "single" && (
                  <div className="row">
                    <div className="col-3">
                      <div className="d-flex align-items-center inputData">
                        <ExInput
                          type={`text`}
                          label={`재고`}
                          value={optionSingle[0].stock}
                          // errorMessage={error?.day}
                          onChange={(e) => {
                            let newOptions = [...optionSingle];
                            newOptions[0] = {
                              ...newOptions[0],
                              stock: e.target.value
                            };
                            setOptionSingle(newOptions);
                          }}
                        />
                        <span className="ms-1" style={{ marginTop: "29px" }}>
                          개
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/* image */}
                <div className="col-md-6">
                  <div className="inputData file flex-row justify-content-start text-start">
                    <label htmlFor="name">사진</label>
                    <div className="position-relative">
                      <input
                        type="file"
                        className="position-absolute start-0 bottom-0 end-0 mt-auto opacity-0"
                        id="image"
                        // value={name}
                        onChange={(e) => {
                          handleImage(e);
                          e.target.value = null;
                        }}
                        style={{
                          height: "40px"
                        }}
                        accept="image/*"
                      />
                      <div
                        className="d-flex border rounded-2"
                        style={{
                          height: "40px"
                        }}
                      >
                        <div
                          className="bg-gray rounded-start-2 d-flex text-white align-items-center justify-content-center"
                          style={{
                            height: "100%",
                            width: "30%"
                          }}
                        >
                          파일 선택
                        </div>
                        <div
                          className="p-1 d-flex align-items-center"
                          style={{ width: "70%" }}
                        >
                          <span className="text-truncate">{image?.name}</span>
                        </div>
                      </div>
                    </div>
                    {error && (
                      <p className="errorMessage text-start">
                        {error && error?.image}
                      </p>
                    )}
                    {imagePath && (
                      <div className="image-start">
                        <img
                          src={imagePath}
                          alt="ServiceImage"
                          draggable="false"
                          className={`${
                            (!imagePath || imagePath == "") && "d-none"
                          }`}
                          width={"100px"}
                          height={"100px"}
                          // data-image={name}
                          data-class={`showImage`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row  formFooter mt-3">
                <div className="col-12 text-end m0">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`등록`}
                    // onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServiceDialogue;

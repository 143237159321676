import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

export default function DateRange(props) {
  const { serverSearching } = props;
  const today = new Date();
  const [date, setDate] = useState();
  const [endDate, setEndDate] = useState();
  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };
  const handleEndDateChange = (selectedDate) => {
    setEndDate(selectedDate);
  };

  const handleClickSearch = () => {
    serverSearching({ startDate: date, endDate });
  };
  return (
    <div className="co-12 d-flex align-items-center">
      <ReactDatePicker
        selected={date}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        minDate={new Date()}
      />
      <div className="px-2" style={{ height: "40px", marginTop: "7px" }}>
        -
      </div>
      <ReactDatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        dateFormat="yyyy-MM-dd"
        minDate={date}
      />
      <button
        className="py-1 bg-button-search"
        style={{
          marginLeft: "10px",
          borderRadius: "8px",
          height: "40px",
          width: "100px",
          minWidth: "100px"
        }}
        onClick={() => handleClickSearch()}
      >
        검색
      </button>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import { ExInput } from "../../extras/Input";
import UserSearchModal from "./UserSearchModal";

const fakePeopleList = [
  {
    id: 1,
    name: "홍길동",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: ""
  },
  {
    id: 2,
    name: "홍길동",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: ""
  },
  {
    id: 3,
    name: "홍길동",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: ""
  },
  {
    id: 4,
    name: "홍길동",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: ""
  },
  {
    id: 5,
    name: "홍길동",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: ""
  }
];

export default function AddLeaderModal(props) {
  const { title, subTitle } = props;

  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [leader, setLeader] = useState("");
  const values = useRef(null);

  const [openSearchModal, setOpenSearchModal] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [selected, setSelected] = React.useState([]);

  function handleSelect(e, id) {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  }

  function handleSelectAll(e) {
    if (e.target.checked) {
      setSelected(data.map((item) => item.id)); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
      setType(dialogueData?.type === "관리팀장" ? "management" : "sale");
      setLeader(dialogueData?.name);
    }
    setData(fakePeopleList);
  }, [dialogueData]);

  const handleChangePercent = (e, id) => {
    let newData = values.current || [];
    const findIndex = newData.findIndex((item) => item.id === id);
    if (findIndex === -1) {
      newData = [
        ...newData,
        {
          id,
          value: e.target.value
        }
      ];
    } else {
      newData[findIndex] = {
        ...newData[findIndex],
        value: e.target.value
      };
    }
    values.current = newData;
  };

  const mapData = [
    {
      Header: (
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            style={{ height: "17px", width: "17px" }}
            checked={selected.length === data.length}
            onChange={handleSelectAll}
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <input
            type="checkbox"
            style={{ height: "17px", width: "17px" }}
            checked={selected.includes(row?.id)}
            onChange={(e) => handleSelect(e, row?.id)}
          />
        </div>
      )
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleInfo(row?._id)}
        >
          {row?.name}
        </button>
      )
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleInfo(row?._id)}
        >
          {row?.nickname}
        </button>
      )
    },
    {
      Header: `휴대폰 번호`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.phone}</span>
    },
    {
      Header: `바텐더 등록 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.bartenderStatus}</span>
      )
    },
    {
      Header: `정산 % 입력`,
      Cell: ({ row }) => (
        <>
          {selected.includes(row?.id) ? (
            <input
              key={row.id}
              type={"text"}
              defaultValue={
                values.current?.find((item) => item.id === row.id)?.value ||
                row?.settlementPercent
              }
              className="rounded width-70 text-center"
              onChange={(e) => handleChangePercent(e, row?.id)}
            />
          ) : (
            <span className="text-capitalize">
              {values.current?.find((item) => item.id === row.id)?.value ||
              row?.settlementPercent
                ? (values.current?.find((item) => item.id === row.id)?.value ||
                    row?.settlementPercent) + "%"
                : ""}
            </span>
          )}
        </>
      )
    }
  ];

  return (
    <>
      <div className="dialog">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-11">
              <div className="mainDiaogBox position-relative">
                <div className="row justify-content-between align-items-center formHead">
                  <div className="col-8">
                    <h4 className="text-theme m0">팀장 추가</h4>
                  </div>
                  <div className="col-4">
                    <div
                      className="closeButton"
                      onClick={() => {
                        dispatch(closeDialog());
                      }}
                    >
                      <i className="ri-close-line"></i>
                    </div>
                  </div>
                </div>
                <div className="row align-items-start formBody">
                  <div className="col-6 d-flex">
                    <ExInput
                      type={`text`}
                      label={"이름"}
                      value={leader}
                      disabled
                    />
                    <Button
                      type={`submit`}
                      className={`text-white m10-left d-flex align-items-center justify-content-center mt-auto`}
                      style={{
                        backgroundColor: "#E7E6E6",
                        height: "40px",
                        width: "120px",
                        minWidth: "120px",
                        marginBottom: "5px"
                      }}
                      text={`검색`}
                      onClick={() => setOpenSearchModal(true)}
                    />
                  </div>
                  <div
                    className="d-flex align-items-center inputData gap-4"
                    style={{ marginTop: "5px", height: "40px" }}
                  >
                    <label>분류</label>
                    <ExInput
                      type={`radio`}
                      label={`관리팀장`}
                      name={"type"}
                      value={`management`}
                      checked={type === "management" && true}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />

                    <ExInput
                      type={`radio`}
                      label={`영업팀장`}
                      name={"type"}
                      value={`sale`}
                      checked={type === "sale" && true}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    />
                  </div>
                  <p className="mb-4 titleTable">인원 등록</p>
                  <div className="col-12">
                    <Table
                      data={data}
                      mapData={mapData}
                      classWrapper={"mainTable mainTableModal"}
                      PerPage={rowsPerPage}
                      Page={page}
                      type={"client"}
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                    <Button
                      className={`bg-gray text-light`}
                      text={`취소`}
                      type={`button`}
                      onClick={() => dispatch(closeDialog())}
                    />
                    <Button
                      type={`submit`}
                      className={` text-white m10-left`}
                      style={{ backgroundColor: "#1ebc1e" }}
                      text={`저장`}
                      // onClick={(e) => handleSubmit(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openSearchModal && (
        <UserSearchModal
          onClose={() => {
            setOpenSearchModal(false);
          }}
          onSelect={(name) => {
            setLeader(name);
          }}
        />
      )}
    </>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { getAllSalons, salonDelete } from "../../../redux/slice/salonSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import moment from "moment";
import CSDetailModal from "./CSDetailModal";

export const Dummy = (count) => {
  const dummyFAQ = [];
  for (let i = 0; i < count; i++) {
    dummyFAQ.push({
      _id: i + 1,
      date: new Date("2024-12-01"),
      branch: "A",
      detail:
        i +
        `스토어바 강남역점 이용 시 불편사항이 있어 문의 접수 합니다. \n 스토어바 강남역점 이용 시 불편사항이 있어 문의 접수 합니다. \n 스토어바 강남역점 이용 시 불편사항이 있어 문의 접수 합니다. `,
      type: i < 2 ? "앱 문의" : i < 5 ? "매장 이용" : "결제",
      name: "홍길동",
      phone: "010-1234-123" + i,
      email: "email@email.com",
      status: i < 2 ? "처리 완료" : i < 5 ? "대기중" : "처리 완료"
    });
  }
  return dummyFAQ;
};

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "detail",
    text: "내용"
  },
  {
    value: "type",
    text: "카테고리"
  }
];

export const SearchingWithSelect = (props) => {
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const {
    data,
    setData,
    type,
    typeSearch,
    serverSearchingType,
    serverSearchingValue,
    button
  } = props;

  const handleSearch = (value) => {
    let searchValue = search ? search : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingValue(searchValue);
    }
  };

  const handleSearchType = (value) => {
    let searchValue = searchType ? searchType : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingType(searchValue);
    }
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            value="이름"
            disabled
          />
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            onChange={
              button
                ? (e) => setSearch(e.target.value)
                : (e) => handleSearch(e.target.value)
            }
          />
          <button
            className="py-1 bg-button-search"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            // onClick={() => handleOpenBookings(row)}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

export const CSList = () => {
  const dummy = useMemo(() => Dummy(15), []);
  const [data, setData] = useState([]);

  const { salon } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search
    };
    dispatch(getAllSalons(payload));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(dummy);
  }, [dummy]);

  const noticeTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "접수 일시",
      Cell: ({ row }) => (
        <span>{row?.date && moment(row.date).format("YYYY-MM-DD")}</span>
      )
    },
    {
      Header: "접수 매장",
      Cell: ({ row }) => <span>{row?.branch}</span>
    },
    {
      Header: "문의 유형",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.type}</span>
    },

    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          //   onClick={() => handleOpenEdit(row)}
        >
          {row?.name}
        </button>
      )
    },
    {
      Header: "번호",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.phone}</span>
    },
    {
      Header: "수신 이메일",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.email}</span>
    },
    {
      Header: "상태",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.status}</span>
    },
    {
      Header: "상세 보기",
      Cell: ({ row }) => (
        <span className="">
          <button
            className="py-1 text-decoration-underline"
            // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
            style={{ backgroundColor: "#fff" }}
            onClick={() => handleOpenDetail(row)}
          >
            상세 보기
          </button>
        </span>
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "FAQ 삭제",
        text: "해당 FAQ를 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDetail = (row) => {
    dispatch(openDialog({ type: "csDetail", data: row }));
  };

  return (
    <div className="userTable">
      <Title name="CS 리스트" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect
            type={`server`}
            data={dummy}
            typeSearch={typeSearch}
            setData={setData}
            column={noticeTable}
            serverSearchingType={handleFilterData}
            serverSearchingValue={handleFilterDataType}
            button
          />
        </div>
      </div>
      <div>
        <Table
          data={dummy}
          mapData={noticeTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
      {dialogue && dialogueType === "csDetail" && (
        <CSDetailModal setData={setData} data={data} />
      )}
    </div>
  );
};

import React from "react";
import { ExInput } from "./Input";

export default function GenderGroup(props) {
  const { label, gender, group, onChange } = props;
  return (
    <div className={`inputData flex-row justify-content-start text-start`}>
      {label && <label>{label}</label>}
      <div
        className="d-flex align-items-center"
        style={{ marginTop: "5px", height: "40px" }}
      >
        <div
          className="col-6"
          style={{
            marginBottom: 0
          }}
        >
          <ExInput
            type={`radio`}
            id={`other`}
            label={`남`}
            name={group}
            value={`other`}
            checked={(gender === "other" || gender === "Other") && true}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>

        <div
          className="col-6 ms-2"
          style={{
            marginBottom: 0
          }}
        >
          <ExInput
            type={`radio`}
            id={`female`}
            label={`여`}
            name={group}
            value={`female`}
            checked={(gender === "female" || gender === "Female") && true}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

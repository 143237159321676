import React, { useEffect, useMemo, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import { SearchingWithSelect } from "../../extras/Searching";

const fakePeopleList = [
  {
    id: 1,
    name: "홍길동1",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: "5%"
  },
  {
    id: 2,
    name: "홍길동2",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: "5%"
  },
  {
    id: 3,
    name: "홍길동3",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: "5%"
  },
  {
    id: 4,
    name: "홍길동4",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: "5%"
  },
  {
    id: 5,
    name: "홍길동6",
    nickname: "홍길동",
    phone: "010-1234-5678",
    bartenderStatus: "등록",
    settlementPercent: "5%"
  }
];

const typeSearch = [
  {
    value: "name",
    text: "이름"
  },
  {
    value: "nickname",
    text: "닉네임"
  }
];

export default function UserSearchModal(props) {
  const { onClose, onSelect } = props;
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setData(fakePeopleList || []);
  }, []);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const mapData = [
    {
      Header: "이름",
      Cell: ({ row }) => <span>{row?.name}</span>
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => <span>{row?.nickname}</span>
    },
    {
      Header: `휴대폰 번호`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.phone}</span>
    },
    {
      Header: `바텐더 등록 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.bartenderStatus}</span>
      )
    },
    {
      Header: "선택",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          onClick={() => {
            onSelect(row?.name);
            onClose();
          }}
        >
          선택
        </button>
      )
    }
  ];

  return (
    <div
      className="dialog"
      style={{
        zIndex: "1000 !important"
      }}
    >
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">사용자 검색</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-md-8 col-lg-7 inputData">
                  <SearchingWithSelect
                    type={`server`}
                    data={data}
                    typeSearch={typeSearch}
                    selectPlaceholder="선택"
                    setData={setData}
                    column={mapData}
                    serverSearchingType={handleFilterData}
                    serverSearchingValue={handleFilterDataType}
                    button
                  />
                </div>
                <div className="col-12">
                  <Table
                    data={data}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal tableSchedule"}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"client"}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    className={`text-white`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`취소`}
                    onClick={() => onClose()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

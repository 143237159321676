import React, { useEffect, useState } from "react";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import ReactDatePicker from "react-datepicker";

const CouponAddModal = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [name, setName] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [content, setContent] = useState();
  const [discount, setDiscount] = useState();

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.name);
      setContent(dialogueData?.content);
      setStart(dialogueData?.start);
      setEnd(dialogueData?.end);
      setDiscount(dialogueData?.discount);
    }
  }, [dialogueData]);

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">쿠폰 등록</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"쿠폰명"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={"제목을 입력하세요. (공백 포함 40자 이내)"}
                  />
                </div>
                <div className="co-12 inputData">
                  <label>적용기간</label>
                  <div className=" d-flex align-items-center">
                    <ReactDatePicker
                      selected={start}
                      onChange={(value) => setStart(value)}
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date()}
                    />
                    <div
                      className="px-2"
                      style={{ height: "40px", marginTop: "7px" }}
                    >
                      -
                    </div>
                    <ReactDatePicker
                      selected={end}
                      onChange={(value) => setEnd(value)}
                      dateFormat="yyyy-MM-dd"
                      // minDate={date}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"할인율"}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    placeholder={"할인율을 입력하세요 (%입력)"}
                  />
                </div>

                <div className="col-12">
                  <Textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    label={`쿠폰 내용`}
                    placeholder={`내용을 입력하세요. (공백 포함 200자 이내)`}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={` text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  // onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponAddModal;

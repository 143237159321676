import React, { useEffect, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import moment from "moment";

const listMenu = [
  {
    id: "storeManagement",
    label: "매장관리",
    checked: false
  },
  {
    id: "reservationList",
    label: "예약 리스트",
    checked: false
  },
  {
    id: "memberManagement",
    label: "회원 관리",
    checked: false
  },
  {
    id: "settlementManagement",
    label: "정산 관리",
    checked: false
  },
  {
    id: "announcementSettings",
    label: "공지사항 설정",
    checked: false
  },
  {
    id: "registerAdministrator",
    label: "관리자 등록",
    checked: false
  },
  {
    id: "fixOtherApps",
    label: "기타 앱 수정",
    checked: false
  }
];

const storeList = [
  "스토어바 강남역점",
  "한잔해바 서래마을",
  "스토어바 홍대",
  "스토어바 역삼역점",
  "한잔해바 강변",
  "가나다라마바",
  "한잔해바 성수",
  "스토어바 상수",
  "스토어바 상수"
];

const AddPermissionModal = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");

  const [listCheckbox, setListCheckbox] = useState(listMenu);
  const [storeSelected, setStoreSelected] = useState([]);

  const handleSelect = (value) => {
    if (storeSelected.includes(value)) {
      const newSelected = [...storeSelected].filter((item) => item !== value);
      setStoreSelected(newSelected);
    } else {
      setStoreSelected([...storeSelected, value]);
    }
  };

  const handleCheck = (id) => {
    const newListCheckbox = [...listCheckbox];
    const itemIndex = listCheckbox.findIndex((item) => item.id === id);
    newListCheckbox[itemIndex] = {
      ...newListCheckbox[itemIndex],
      checked: !newListCheckbox[itemIndex].checked
    };
    setListCheckbox(newListCheckbox);
  };

  const handleRemoveStore = (value) => {
    const newStoreSelected = [...storeSelected].filter(
      (item) => item !== value
    );
    setStoreSelected(newStoreSelected);
  };

  const handleResetStore = () => {
    setStoreSelected([]);
  };
  const handleSelectAll = () => {
    setStoreSelected(storeList);
  };

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-7 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">권한 추가</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"분류"}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                </div>
                <div className="col-12 inputData">
                  <label>접근 메뉴</label>
                  <div className="d-flex gap-4 flex-wrap mt-2">
                    {listCheckbox.map((item) => (
                      <div
                        key={item.id}
                        className="d-flex flex-row-reverse align-items-center gap-2"
                      >
                        <label htmlFor={item.id} className="text-nowrap">
                          {item.label}
                        </label>
                        <input
                          id={item.id}
                          type="checkbox"
                          style={{ height: "17px", width: "17px" }}
                          checked={item.checked}
                          onClick={() => handleCheck(item.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {listCheckbox[0].checked && (
                  <div className="col-12">
                    <div className="inputData d-flex align-items-center justify-content-between">
                      <label>관리 매장 선택</label>
                      <Button
                        className={`text-black m10-left `}
                        style={{
                          backgroundColor: "#E7E6E6"
                        }}
                        text={`전체 선택`}
                        onClick={handleSelectAll}
                      />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div
                          className="row align-items-start formBody px-2"
                          style={{
                            columnGap: "20px"
                          }}
                        >
                          {storeList.map((item) => (
                            <div
                              className={`border rounded col-4 d-flex align-items-center cursor-pointer px-20 py-0 ${
                                storeSelected.includes(item)
                                  ? "bg-gray"
                                  : "bg-white"
                              }`}
                              style={{
                                height: "40px",
                                flexBasis: "calc(100%/3 - 20px)"
                              }}
                              key={item}
                              onClick={() => handleSelect(item)}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="row flex-grow-1 align-items-start formBody px-2">
                            {storeSelected.map((item) => (
                              <div
                                className={`border bg-gray text-nowrap rounded d-flex align-items-center px-20 py-0`}
                                style={{
                                  height: "40px",
                                  maxWidth: "fit-content"
                                }}
                                key={item}
                              >
                                {item}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="cursor-pointer"
                                  style={{
                                    width: "24px",
                                    height: "24px"
                                  }}
                                  onClick={() => handleRemoveStore(item)}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </div>
                            ))}
                          </div>
                          <Button
                            className={`text-black m10-left `}
                            style={{
                              backgroundColor: "#E7E6E6",
                              height: "40px",
                              width: "100px",
                              minWidth: "100px"
                            }}
                            text={`초기화`}
                            onClick={handleResetStore}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{
                    backgroundColor: "#E7E6E6"
                  }}
                  text={`취소`}
                  onClick={() => {
                    dispatch(closeDialog());
                  }}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  // onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPermissionModal;

import React, { useState } from "react";
import Button from "../../extras/Button";
import Title from "../../extras/Title";
import { ExInput } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import AddCategory from "./AddCategory";
import AddTag from "./AddTag";
import RecommendTag from "./RecommendTag";

const fakeHashTags = [
  {
    title: "기본 태그1",
    list: ["#플레어바텐더", "#비밀보장", "#비밀친구", "#개인맞춤"]
  },
  {
    title: "분위기",
    list: ["#친절함"]
  },
  {
    title: "기본 태그",
    list: ["#플레어바텐더", "#비밀보장", "#비밀친구", "#개인맞춤"]
  }
];

const fakeRecommendTag = ["#음료추천전문", "#믹솔로지전문가", "#친절함"];

export default function HashTag() {
  const [limit, setLimit] = useState();
  const dispatch = useDispatch();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [data, setData] = useState([]);

  const handleOpenAddCategory = () => {
    dispatch(openDialog({ type: "addHashTagCategory", data: fakeHashTags }));
  };

  const handleOpenAddHashTag = (data) => {
    dispatch(openDialog({ type: "addHashTag", data: data }));
  };

  const handleOpenAddRecommend = () => {
    dispatch(openDialog({ type: "addRecommend", data: null }));
  };

  return (
    <div className="p-3">
      <Title name={"바텐더 해시태그 설"} />
      <div className="row">
        <div className="col-12 col-md-6 d-flex ">
          <ExInput
            type={`text`}
            value={limit}
            label={`최대 설정 가능 해시테그 개수`}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
          />
          <Button
            type={`submit`}
            className={`text-black m10-left fw-bold mt-auto d-flex align-items-center justify-content-center`}
            style={{
              height: "40px",
              marginBottom: "5px",
              width: "100px"
            }}
            text={`저장`}
            // onClick={onsubmit}
          />
        </div>
      </div>
      <div className="inputData">
        <span>해시태그</span>
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white cursor-pointer"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#1ebc1e"
          viewBox="0 0 24 24"
          onClick={handleOpenAddCategory}
        >
          <path
            fill-rule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div className="card">
        <div className="card-body">
          {fakeHashTags.map((hashTag) => (
            <div className="row" key={hashTag.title}>
              <div className="inputData d-flex align-items-center">
                <span>{hashTag.title}</span>
                <Button
                  type={`submit`}
                  className={`text-black m10-left fw-bold mt-auto d-flex align-items-center justify-content-center`}
                  style={{
                    height: "40px",
                    marginBottom: "5px",
                    width: "100px"
                  }}
                  text={`설정`}
                  onClick={() => handleOpenAddHashTag(hashTag)}
                />
              </div>
              <div className="col-12 row">
                {hashTag.list.map((tag) => (
                  <div className="col-4" key={tag}>
                    <ExInput
                      type={`text`}
                      label={``}
                      newClass={"bg-gray"}
                      value={tag}
                      disabled={true}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-4">
        <div className="inputData d-flex align-items-center">
          <span>추천 해시태그</span>
          <Button
            type={`submit`}
            className={`text-black m10-left fw-bold mt-auto d-flex align-items-center justify-content-center`}
            style={{
              height: "40px",
              marginBottom: "5px",
              width: "100px"
            }}
            text={`설정`}
            onClick={handleOpenAddRecommend}
          />
        </div>
        <div className="col-12 row">
          {fakeRecommendTag.map((tag) => (
            <div className="col-4" key={tag}>
              <ExInput
                type={`text`}
                label={``}
                newClass={"bg-gray"}
                value={tag}
                disabled={true}
              />
            </div>
          ))}
        </div>
      </div>
      {dialogue && dialogueType === "addHashTagCategory" && (
        <AddCategory setData={setData} data={data} />
      )}
      {dialogue && dialogueType === "addHashTag" && (
        <AddTag setData={setData} data={data} />
      )}
      {dialogue && dialogueType === "addRecommend" && (
        <RecommendTag setData={setData} data={data} />
      )}
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { getAllSalons, salonDelete } from "../../../redux/slice/salonSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import moment from "moment";
import PeopleListModal from "./PeopleListModal";
import AddLeaderModal from "./AddLeaderModal";

export const Dummy = (count) => {
  const dummyLeader = [];
  for (let i = 0; i < count; i++) {
    dummyLeader.push({
      _id: i + 1,
      date: new Date("2024-12-01"),
      name: "홍길동",
      phone: "010-1234-567" + i,
      email: "email@email.com",
      peopleList: [
        {
          name: "홍길동",
          nickname: "홍길동",
          phone: "010-1234-5678",
          bartenderStatus: "등록",
          settlementPercent: "5%"
        },
        {
          name: "홍길동",
          nickname: "홍길동",
          phone: "010-1234-5678",
          bartenderStatus: "등록",
          settlementPercent: "5%"
        },
        {
          name: "홍길동",
          nickname: "홍길동",
          phone: "010-1234-5678",
          bartenderStatus: "등록",
          settlementPercent: "5%"
        },
        {
          name: "홍길동",
          nickname: "홍길동",
          phone: "010-1234-5678",
          bartenderStatus: "등록",
          settlementPercent: "5%"
        },
        {
          name: "홍길동",
          nickname: "홍길동",
          phone: "010-1234-5678",
          bartenderStatus: "등록",
          settlementPercent: "5%"
        }
      ],
      category: i < 1 ? "관리팀장" : "영업팀장"
    });
  }
  return dummyLeader;
};

export const LeaderList = () => {
  const dummy = useMemo(() => Dummy(3), []);
  const [data, setData] = useState([]);

  const { salon } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search
    };
    dispatch(getAllSalons(payload));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(dummy);
  }, [dummy]);

  const noticeTable = [
    {
      Header: "번호",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "팀장 구분",
      Cell: ({ row }) => <span>{row?.category}</span>
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenPeople(row)}
        >
          {row?.name}
        </button>
      )
    },
    {
      Header: "휴대폰 번호",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.phone}</span>
    },
    {
      Header: "등록 인원",
      Cell: ({ row }) => (
        <span className="truncate-line-2">
          {row?.peopleList.length || 0} 명
        </span>
      )
    },
    {
      Header: "생성일",
      Cell: ({ row }) => (
        <span>{row?.date && moment(row.date).format("YYYY-MM-DD")}</span>
      )
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleDelete(row?.id)}
        >
          삭제
        </button>
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "팀장 삭제하기",
        text: "해당 팀장 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenEdit = (row) => {
    dispatch(openDialog({ type: "addLeader", data: row }));
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "addLeader", data: null }));
  };

  const handleOpenPeople = (row) => {
    dispatch(openDialog({ type: "peopleList", data: row }));
  };

  return (
    <div className="userTable">
      <Title name="팀장 리스트/등록" />
      <p className="fs-18">팀장 리스트</p>
      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>총 팀장 {data.length} 명</label>
        </div>
        <Button
          className={`bg-button-excel p-10 text-black font-bold m10-bottom ms-auto mt-auto`}
          text={`팀장 추가`}
          style={{ height: "40px" }}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={dummy}
          mapData={noticeTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
      {dialogue && dialogueType === "peopleList" && (
        <PeopleListModal setData={setData} data={data} />
      )}
      {dialogue && dialogueType === "addLeader" && (
        <AddLeaderModal setData={setData} data={data} />
      )}
    </div>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import { getAllSalons, salonDelete } from "../../../redux/slice/salonSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import moment from "moment";
import AddPermissionModal from "./AddPermissionModal";

export const Dummy = (count) => {
  const dummyFAQ = [];
  for (let i = 0; i < count; i++) {
    dummyFAQ.push({
      _id: i + 1,
      date: new Date("2024-12-01"),
      name: "홍길동",
      phone: "010-1234-123" + i,
      email: "email@email.com",
      category: i < 2 ? "마스터" : "스토어바 역삼, 상수, 강남 관리자"
    });
  }
  return dummyFAQ;
};

export const PermissionList = () => {
  const dummy = useMemo(
    () => [
      {
        type: "마스터",
        storeManagement: true,
        reservationList: true,
        memberManagement: true,
        settlementManagement: true,
        noticeSettings: true,
        otherAppModification: true,
        adminRegistration: true
      },
      {
        type: "일반 관리자",
        storeManagement: true,
        reservationList: true,
        memberManagement: true,
        settlementManagement: true,
        noticeSettings: true,
        otherAppModification: false,
        adminRegistration: false
      },
      {
        type: "스토어바 역삼, 상수, 강남 관리자",
        storeManagement: true,
        reservationList: true,
        memberManagement: true,
        settlementManagement: false,
        noticeSettings: true,
        otherAppModification: false,
        adminRegistration: false
      }
    ],
    []
  );
  const [data, setData] = useState([]);

  const { salon } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search
    };
    dispatch(getAllSalons(payload));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(dummy);
  }, [dummy]);

  const noticeTable = [
    {
      Header: "번호",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "분류",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFE7CF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          //   onClick={() => handleOpenEdit(row)}
        >
          {row?.type}
        </button>
      )
    },
    {
      Header: "매장 관리",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.storeManagement}
        />
      )
    },
    {
      Header: "예약 리스트",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.reservationList}
        />
      )
    },
    {
      Header: "	회원 관리",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.memberManagement}
        />
      )
    },
    {
      Header: "정산 관리",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.settlementManagement}
        />
      )
    },
    {
      Header: "공지사항 설정",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.noticeSettings}
        />
      )
    },
    {
      Header: "기타  앱 수정",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.otherAppModification}
        />
      )
    },
    {
      Header: "관리자 등록",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          style={{ height: "17px", width: "17px" }}
          checked={row?.adminRegistration}
        />
      )
    }
  ];

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "FAQ 삭제",
        text: "해당 FAQ를 삭제하시겠습니까?",
        confirm: "확인",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenDetail = (row) => {
    dispatch(openDialog({ type: "csDetail", data: row }));
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "addPermission", data: null }));
  };

  return (
    <div className="userTable">
      <Title name="권한 관리" />

      <div className="betBox mb-3 justify-content-end">
        <Button
          className={`bg-button-excel p-10 text-black font-bold m10-bottom ms-auto mt-auto`}
          text={`추가`}
          style={{ height: "40px" }}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={dummy}
          mapData={noticeTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
      {dialogue && dialogueType === "addPermission" && (
        <AddPermissionModal setData={setData} data={data} />
      )}
    </div>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";

export default function PeopleListModal(props) {
  const { title, subTitle } = props;
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");
  const [data, setData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [selected, setSelected] = React.useState([]);

  function handleSelect(e, id) {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  }

  function handleSelectAll(e) {
    if (e.target.checked) {
      setSelected(data.map((item) => item.id)); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
      setData(dialogueData?.peopleList || []);
    }
  }, [dialogueData]);

  const mapData = [
    {
      Header: "이름",
      Cell: ({ row }) => <span>{row?.name}</span>
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => <span>{row?.nickname}</span>
    },
    {
      Header: `휴대폰 번호`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.phone}</span>
    },
    {
      Header: `바텐더 등록 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.bartenderStatus}</span>
      )
    },
    {
      Header: `정산 %`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementPercent}</span>
      )
    }
  ];

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">홍길동 등록 인원</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <p className="mb-1 titleTable">총 {data.length}명</p>
                <div className="col-12">
                  <Table
                    data={data}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal tableSchedule"}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"client"}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    className={`text-white`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`닫기`}
                    onClick={() => dispatch(closeDialog())}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

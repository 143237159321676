import React, { useEffect, useMemo, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import { ExInput } from "../../extras/Input";
import { useNavigate } from "react-router-dom";

export default function IssueCouponModal() {
  const navigate = useNavigate();
  const dummySettlement = useMemo(
    () => [
      {
        id: 1,
        name: "홍길동",
        nickname: "김길동",
        phone: "010-1234-1234",
        isBartender: true,
        depositAccountNumber: "000000-00-000000",
        targetAmount: "80000",
        point: "76333",
        status: "미승인"
      },
      {
        id: 2,
        name: "김철수",
        nickname: "김길동",
        phone: "010-1234-1234",
        isBartender: false,
        depositAccountNumber: "000000-00-000000",
        targetAmount: "80000",
        point: "76333",
        status: "김철수"
      }
    ],
    []
  );
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [selected, setSelected] = React.useState([]);

  function handleSelect(e, id) {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  }

  function handleSelectAll(e) {
    if (e.target.checked) {
      setSelected(dummySettlement.map((item) => item.id)); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
    }
  }, [dialogueData]);

  const handleOpenProfile = (row) => {
    navigate("/admin/user/userProfile", {
      state: {
        row
      }
    });
  };

  const mapData = [
    {
      Header: "이름",
      Cell: ({ row }) => <span className="text-capitalize">{row?.name}</span>
    },
    {
      Header: `닉네임`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.nickname}</span>
      )
    },
    {
      Header: `휴대폰 번호`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.phone}</span>
    },

    {
      Header: "보유 포인트",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.point || 0}p</span>
      )
    },
    {
      Header: `바텐더 등록 여부`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.isBartender ? "등록" : "미등록"}
        </span>
      )
    },
    {
      Header: `프로필 상세`,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenProfile(row)}
        >
          프로필 보기
        </button>
      )
    }
  ];

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">쿠폰 발급</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <p className="mb-1 titleTable">지급 유저 목록</p>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"쿠폰 명"}
                    value={"10% 할인 쿠폰"}
                  />
                </div>
                <div className="col-12 d-flex align-items-center">
                  <div className="inputData">
                    <label htmlFor="">발급 유저 등록</label>
                  </div>
                  <Button
                    className={`bg-gray text-light ms-2`}
                    text={`Excel 업로드`}
                    type={`button`}
                    // onClick={() => dispatch(closeDialog())}
                  />
                </div>
                <div className="col-12">
                  <Table
                    data={dummySettlement}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal"}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"client"}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={` text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`발급`}
                    // onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { closeDialog } from "../../redux/slice/dialogueSlice";
import Table from "../extras/Table";
import { useDispatch, useSelector } from "react-redux";
import Button from "../extras/Button";

const typeSearch = [
  // {
  //   value: "",
  //   text: "전체"
  // },
  {
    value: "Unapproved",
    text: "미승인"
  },
  {
    value: "Rejected",
    text: "거절"
  },
  {
    value: "Approved",
    text: "승인"
  },
  {
    value: "Completed",
    text: "완료"
  }
];

export default function SettlementInforModal() {
  const dummySettlement = useMemo(
    () => [
      {
        id: 1,
        name: "홍길동",
        phone: "010-1234-1234",
        depositAccountName: "Kookmin Bank",
        depositAccountNumber: "000000-00-000000",
        targetAmount: "80000",
        settlementAmount: "76333",
        status: "미승인"
      },
      {
        id: 2,
        name: "김철수",
        phone: "010-1234-1234",
        depositAccountName: "Kookmin Bank",
        depositAccountNumber: "000000-00-000000",
        targetAmount: "80000",
        settlementAmount: "76333",
        status: "김철수"
      }
    ],
    []
  );
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [searchType, setSearchType] = useState("");
  const [selected, setSelected] = React.useState([]);

  function handleSelect(e, id) {
    if (e.target.checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  }

  function handleSelectAll(e) {
    if (e.target.checked) {
      setSelected(dummySettlement.map((item) => item.id)); // select all
    } else {
      // if false
      setSelected([]); // unselect all
    }
  }

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
    }
  }, [dialogueData]);

  const mapData = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleInfo(row?._id)}
        >
          {row?.name}
        </button>
      )
    },
    {
      Header: `전화번호`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.phone}</span>
    },
    {
      Header: `입금 계좌`,
      Cell: ({ row }) => (
        <div className="text-center d-flex flex-column">
          <span className="text-capitalize">{row?.depositAccountName}</span>
          <span className="text-capitalize">{row?.depositAccountNumber}</span>
        </div>
      )
    },

    {
      Header: "총 정산 대상 금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.targetAmount}</span>
      )
    },
    {
      Header: `총  정산굼액`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.settlementAmount}</span>
      )
    },
    {
      Header: `정산 여부`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.status}</span>
    }
  ];

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">정산 정보</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <p className="mb-4">계좌별 정산 정보</p>
                <div className="d-flex flex-column inputData">
                  <label>정산 상태</label>
                  <select
                    value={searchType}
                    className="inputTypeSearch"
                    style={{
                      height: "40px",
                      marginTop: "0",
                      width: "110px",
                      minWidth: "110px"
                    }}
                    placeholder="선택"
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    {typeSearch.map((item) => (
                      <option value={item.value} key={item.text}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12">
                  <Table
                    data={dummySettlement}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal"}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"client"}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center gap-4">
                  <Button
                    type={`submit`}
                    className={` text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`확인`}
                    onClick={() => dispatch(closeDialog())}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

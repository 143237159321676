import React, { useState } from "react";
import Button from "../../extras/Button";
import Title from "../../extras/Title";
import { ExInput } from "../../extras/Input";
import ToggleSwitch from "../../extras/ToggleSwitch";
import ReactDatePicker from "react-datepicker";
import { CouponList } from "./CouponList";

export default function CouponSetting() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (selectedDate) => {
    setStartDate(selectedDate);
  };
  const handleEndDateChange = (selectedDate) => {
    setEndDate(selectedDate);
  };

  return (
    <div className="mainSetting">
      <Title name="포인트 사용 및 쿠폰 발급 설정" />
      <div className="settingBox">
        <div className="d-flex justify-content-end">
          <div className="formFooter">
            <Button
              type={`submit`}
              className={`text-light m10-left fw-bold`}
              text={`저장`}
              style={{ backgroundColor: "#1ebc1e" }}
              onClick={onsubmit}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 inputData">
            <label>예약 시 포인트 사용 설정</label>
            <div className="border rounded-2 w-100">
              <div className="border-bottom d-flex">
                <div className="bg-gray rounded-top-start-2 min-width-260 d-flex align-items-center justify-content-center p-2 fs-16">
                  예약 사용 시 보유 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center">
                  <ExInput
                    type={`text`}
                    value={""}
                    label={``}
                    placeholder={""}
                  />
                  <span className="ms-1 fs-15">
                    P 이상 보유한 경우 결제 시 사용 가능
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className="bg-gray min-width-260 rounded-bottom-start-2 d-flex align-items-center justify-content-center p-2 fs-16">
                  예약 시 최소 사용 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center ">
                  <span className="me-1 fs-15">1회 결제 시 최소</span>
                  <ExInput
                    type={`text`}
                    value={""}
                    label={``}
                    placeholder={""}
                  />
                  <span className="ms-1 fs-15">P 이상 사용 가능</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 inputData">
            <label>후원 시 포인트 사용 설정</label>
            <div className="border rounded-2 w-100">
              <div className="border-bottom d-flex">
                <div className="bg-gray rounded-top-start-2 min-width-260 d-flex align-items-center justify-content-center p-2 fs-16">
                  후원 시 보유 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center">
                  <ExInput
                    type={`text`}
                    value={""}
                    label={``}
                    placeholder={""}
                  />
                  <span className="ms-1 fs-15">
                    P 이상 보유한 경우 후원 시 사용 가능
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className="bg-gray min-width-260 rounded-bottom-start-2 d-flex align-items-center justify-content-center p-2 fs-16">
                  후원 시 최소 사용 포인트 제한
                </div>
                <div className="p-2 d-flex align-items-center ">
                  <span className="me-1 fs-15">1회 후원 시 최소</span>
                  <ExInput
                    type={`text`}
                    value={""}
                    label={``}
                    placeholder={""}
                  />
                  <span className="ms-1 fs-15">P 이상 사용 가능</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 inputData">
            <label>쿠폰 발급 설정</label>
            <div className="d-flex align-items-center mb-2">
              <div className="inputData d-flex align-items-center">
                <label className="me-2">최초 회원가입 시</label>
                <ToggleSwitch
                //   onClick={() => handleAppActive(setting?._id)}
                //   value={setting?.maintenanceMode}
                />
              </div>
              <div className="inputData d-flex align-items-center ms-4">
                <label className="me-2">리뷰 작성 시</label>
                <ToggleSwitch
                //   onClick={() => handleAppActive(setting?._id)}
                //   value={setting?.maintenanceMode}
                />
              </div>
            </div>
            <div className="border rounded-2 w-100">
              <div className="d-flex">
                <div className="bg-gray rounded-start-2 min-width-260 d-flex align-items-center justify-content-center p-2 fs-16">
                  발급 쿠폰 설정
                </div>
                <div className="p-2 d-flex align-items-center">
                  <span className="fs-15 me-1 text-nowrap">할인율</span>
                  <ExInput
                    type={`text`}
                    value={""}
                    label={``}
                    placeholder={""}
                  />
                  <span className="ms-1 fs-15 text-nowrap me-3">%</span>
                  <span className="fs-15 me-1 text-nowrap">발급 기간</span>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="yyyy-MM-dd"
                    // minDate={new Date()}
                  />
                  <span className="ms-1 fs-15 text-nowrap me-1">부터</span>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="yyyy-MM-dd"
                    // minDate={new Date()}
                  />
                  <span className="ms-1 fs-15 text-nowrap">까지</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <CouponList />
      </div>
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../extras/Table";
import moment from "moment";
import { closeDialog } from "../../redux/slice/dialogueSlice";

const PaymentDetailModal = (props) => {
  const { allStore } = props;
  const dummySettlement = useMemo(
    () => [
      {
        reservationNumber: "#072201",
        reservationDate: new Date("2024-08-20"),
        branch: "A",
        amount: "20000",
        paymentMethod: "신용카드",
        couponRate: "10%",
        couponAmount: "2000",
        pointAmount: "10000p",
        cardAmount: "8000"
      }
    ],
    []
  );
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
    }
  }, [dialogueData]);

  const mapData = [
    {
      Header: "예약 번호",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.reservationNumber}</span>
      )
    },
    {
      Header: "예약일",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.reservationDate &&
            moment(row.reservationDate).format("YYYY-MM-DD")}
        </span>
      )
    },
    ...(allStore
      ? [
          {
            Header: "매장",
            Cell: ({ row }) => (
              <span className="text-capitalize">{row?.branch}</span>
            )
          }
        ]
      : []),
    {
      Header: `정산 대상 금액`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.amount}</span>
    },
    {
      Header: `결제 방법`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.paymentMethod}</span>
      )
    },

    {
      Header: "쿠폰 할인율",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.couponRate}</span>
      )
    },
    {
      Header: `쿠폰 할인액`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.couponAmount}</span>
      )
    },
    {
      Header: `포인트 결제액`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.pointAmount}</span>
      )
    },
    {
      Header: `카드 결제액`,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.cardAmount}</span>
      )
    }
  ];

  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="mainDiaogBox position-relative">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">결제 상세</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <Table
                    data={dummySettlement}
                    mapData={mapData}
                    classWrapper={"mainTable mainTableModal tableSchedule"}
                    PerPage={rowsPerPage}
                    Page={page}
                    type={"client"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentDetailModal;

import React, { useEffect, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";
import moment from "moment";

const AddAdminModal = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    if (dialogueData) {
      setName(dialogueData?.name);
      setEmail(dialogueData?.email);
      setType(dialogueData?.category);
      setPhone(dialogueData?.phone);
    }
  }, [dialogueData]);

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-7 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">관리자 추가</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"이름"}
                    value={name}
                    placeholder="이름을 입력하세요."
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-12 d-flex">
                  <ExInput
                    type={`text`}
                    label={"이메일(ID)"}
                    newClass="w-100"
                    className="w-100"
                    value={email}
                    placeholder="이메일을 입력하세요. "
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left d-flex align-items-center justify-content-center mt-auto`}
                    style={{
                      backgroundColor: "#E7E6E6",
                      height: "40px",
                      width: "120px",
                      minWidth: "120px",
                      marginBottom: "5px"
                    }}
                    text={`중복 확인`}
                    // onClick={(e) => handleSubmit(e)}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"비밀번호"}
                    value={password}
                    placeholder="비밀번호를 입력하세요."
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"비밀번호 확인"}
                    value={confirmPassword}
                    placeholder="비밀번호를 재입력하세요."
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"휴대폰번호"}
                    value={phone}
                    placeholder="핸드폰 번호를 입력하세요."
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"관리자 구분"}
                    value={type}
                    placeholder="관리자 구분을 선택하세요."
                    onChange={(e) => setType(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{
                    backgroundColor: "#E7E6E6"
                  }}
                  text={`취소`}
                  onClick={() => {
                    dispatch(closeDialog());
                  }}
                />
                <Button
                  type={`submit`}
                  className={`text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  // onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdminModal;

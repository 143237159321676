import React, { useEffect, useState } from "react";
import { ExInput, SelectV2, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";

const AddCategory = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [hashTagList, setHashTagList] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  console.log("dialogueData", dialogueData);

  useEffect(() => {
    if (dialogueData) {
      setHashTagList([
        ...dialogueData,
        {
          title: "특기",
          list: []
        }
      ]);
    }
  }, [dialogueData]);

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-md-8 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">해시태그 카테고리</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="row">
                  {hashTagList.map((tag) => (
                    <div
                      className="col-4 d-flex align-items-centetr"
                      key={tag.title}
                    >
                      <ExInput
                        type={`text`}
                        label={``}
                        newClass={"bg-gray"}
                        value={tag.title}
                        disabled={true}
                      />
                      <div
                        className="col-1 h-100  cursor-pointer"
                        style={{
                          marginTop: "5px",
                          width: "30px",
                          marginLeft: "10px"
                        }}
                        // onClick={() => handleRemoveOption(index)}
                      >
                        <div
                          className="rounded-2 d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "#fff1f1",
                            height: "40px"
                          }}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="#f71845"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-12">
                <div className="d-flex align-items-center">
                  <ExInput
                    type={`text`}
                    label={``}
                    value={newCategory}
                    onChange={(e) => setNewCategory(e.target.value)}
                    placeholder="카테고리를 입력하세요."
                  />
                  <Button
                    className={`bg-gray text- ms-2`}
                    style={{ height: "40px" }}
                    text={`추가`}
                    type={`button`}
                    // onClick={() => dispatch(closeDialog())}
                  />
                </div>
                <div className="d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className={`bg-gray text-light`}
                    text={`취소`}
                    type={`button`}
                    onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-white m10-left`}
                    style={{ backgroundColor: "#1ebc1e" }}
                    text={`저장`}
                    // onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;

import Swal from "sweetalert2";
import { DangerRight } from "../component/api/toastServices";

export const warning = (confirm) => {
  return Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    iconHtml: '<i class="ri-alert-line"></i>',
    showCancelButton: true,
    confirmButtonText: confirm,
    customClass: {
      confirmButton: "btn bg-second text-light m15-right",
      cancelButton: "btn bg-darkGray text-light"
    },
    buttonsStyling: false
  });
};

export const confirm = ({
  title = "Are you sure?",
  text = "You won't be able to revert this!",
  confirm,
  cancle,
  iconHtml = '<i class="ri-alert-line"></i>',
  confirmButton = "btn bg-second text-light m15-right",
  cancelButton = "btn bg-darkGray text-light"
}) => {
  return Swal.fire({
    title: title,
    text: text,
    iconHtml: iconHtml,
    showCancelButton: true,
    confirmButtonText: confirm,
    cancelButtonText: cancle,
    customClass: {
      confirmButton: confirmButton,
      cancelButton: cancelButton
    },
    buttonsStyling: false
  });
};

import React, { useEffect, useMemo, useState } from "react";
import Pagination from "../../extras/Pagination";
import Table from "../../extras/Table";
import Analytics from "../../extras/Analytics";
import Title from "../../extras/Title";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getSalonBookings } from "../../../redux/slice/salonSlice";
import CancleDetails from "../booking/CancleDetails";
import CancelBookingDialog from "../booking/CancelBookingDialog";
import DatePeriod from "../../extras/DatePeriod";
import Button from "../../extras/Button";
import moment from "moment";

const bookingType = [
  { name: "전체", value: "" },
  { name: "예정", value: "Planned" },
  { name: "완료", value: "Completed" },
  { name: "취소", value: "Cancel" }
];

export const Dummy = (count) => {
  const dummyStore = [];
  for (let i = 0; i < count; i++) {
    dummyStore.push({
      _id: i + 1, // Assuming _id is a unique identifier
      storeName: "store_" + i,
      nickName: "nn" + i,
      batenderName: `ba` + i,
      menuName: "ASet",
      price: 7000 * (i + 1.5),
      number: "#77777" + i,
      date: new Date("2024-09-09"),
      resverDate: new Date("2024-09-09 3:00:00"),
      status: i === 0 ? "예정" : i === 1 ? "취소" : "완료",
      paymentMethod: i === 0 ? "현장 결제" : "결제 완료"
    });
  }
  return dummyStore;
};

const SalonBooking = () => {
  const dummyBookings = useMemo(() => Dummy(15), []);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { booking, total } = useSelector((state) => state.salon);
  const { setting } = useSelector((state) => state.setting);

  const state = useLocation();

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [type, setType] = useState("ALL");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const navigate = useNavigate();

  const payload = {
    start: page,
    limit: rowsPerPage,
    salonId: state?.state?.data?._id,
    type,
    startDate,
    endDate
  };

  useEffect(() => {
    dispatch(getSalonBookings({ ...payload, command: true }));
  }, [page, rowsPerPage, state, type, startDate, endDate]);

  // useEffect(() => {
  //   setData(booking);
  // }, [booking]);

  useEffect(() => {
    setData(dummyBookings);
  }, [dummyBookings]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const [search, setSearch] = useState("");

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleInfo = (id) => {
    navigate("/admin/expert/getExpertProfile", {
      state: {
        id
      }
    });
  };

  const handleUserInfo = (id) => {
    navigate("/admin/user/userProfile", {
      state: {
        id
      }
    });
  };

  console.log("state", state);

  const bookingTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    ...(!state?.state?.data?.name
      ? [
          {
            Header: "예약 매장",
            Cell: ({ row }) => (
              <span className="text-capitalize">{row?.storeName}</span>
            )
          }
        ]
      : []),
    {
      Header: "예약자 닉네임",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleScheduleInfo(row)}
        >
          {row?.nickName}
        </button>
      )
    },
    {
      Header: "바텐더 닉네임",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleScheduleInfo(row)}
        >
          {row?.batenderName}
        </button>
      )
    },
    {
      Header: "주문 메뉴",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.menuName}</span>
      )
    },
    {
      Header: "가격",
      Cell: ({ row }) => <span className="text-capitalize">{row?.price}원</span>
    },
    {
      Header: "예약 번호",
      Cell: ({ row }) => <span className="text-capitalize">{row?.number}</span>
    },
    {
      Header: state?.state?.data?.name ? `예약 신청일` : `예약일`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {state?.state?.data?.name
            ? row?.date && moment(row.date).format("YYYY-MM-DD")
            : row?.date && moment(row.resverDate).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: state?.state?.row?.name ? "예약 시간" : "예약 시간",
      Cell: ({ row }) => (
        <span className="text-uppercase">
          {state?.state?.row?.name
            ? row?.resverDate &&
              moment(row.resverDate).format("YYYY-MM-DD hh:mm a")
            : row?.resverDate && moment(row.resverDate).format("hh:mm a")}
        </span>
      )
    },
    {
      Header: "상태",
      Cell: ({ row }) =>
        row?.status === "예정" ? (
          <button
            className="text-white m5-right p12-x p4-y fs-12 br-5"
            style={{ backgroundColor: "#00b0f0" }}
          >
            예정
          </button>
        ) : row?.status === "취소" ? (
          <button
            className="text-white m5-right p12-x p4-y fs-12 br-5"
            style={{ backgroundColor: "#ff7512", cursor: "pointer" }}
            onClick={() => handleOpenDialogue(row)}
          >
            취소
          </button>
        ) : (
          <button
            className="text-white m5-right p12-x p4-y fs-12 br-5"
            style={{ backgroundColor: "#00b050" }}
          >
            완료
          </button>
        )
    },
    {
      Header: "결제 여부",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.paymentMethod}</span>
      )
    }
  ];

  const handleCancel = (row) => {
    dispatch(openDialog({ type: "cancelBooking", data: row }));
  };

  const handleOpenDialogue = (row) => {
    dispatch(openDialog({ type: "cancel", data: row }));
  };

  return (
    <div className="mainBooking">
      <Title
        name={
          state?.state?.data?.name
            ? `스토어바 ${state ? state?.state?.data?.name : ""}역점 예약 현황`
            : "예약 현황"
        }
      />

      <div className="d-flex align-items-center mb-4">
        <div className="inputData d-flex flex-column">
          <label className="styleForTitle" htmlFor="bookingType">
            예약 상태
          </label>
          <select
            name="bookingType"
            className="rounded-2 fw-bold inputTypeSearch"
            style={{
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            id="bookingType"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            {bookingType?.map((data) => {
              return (
                <option value={data?.value} key={data?.name}>
                  {data?.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="ms-4">
          <div className="inputData" style={{ marginTop: "-2px" }}>
            <label>검색 기간</label>
          </div>
          <div style={{ marginTop: "5px" }}>
            <DatePeriod
              analyticsStartDate={startDate}
              analyticsStartEnd={endDate}
              analyticsStartDateSet={setStartDate}
              analyticsStartEndSet={setEndDate}
            />
          </div>
        </div>
        <Button
          className={`bg-button-excel d-flex align-items-center justify-content-center p-10 text-black  ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`매장 추가`}
          // bIcon={`fa-solid fa-user-plus`}
          // onClick={() => handleAddSalon()}
        />
      </div>

      <div>
        <Table
          data={data}
          mapData={bookingTable}
          serverPerPage={rowsPerPage}
          serverSearching={handleFilterData}
          type={"server"}
        />
        <Pagination
          type={"server"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "cancel" && (
        <CancleDetails setData={setData} data={data} />
      )}
      {dialogue && dialogueType === "cancelBooking" && (
        <CancelBookingDialog setData={setData} data={data} />
      )}
    </div>
  );
};

export default SalonBooking;

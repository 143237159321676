import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExInput } from "../../extras/Input";
import Button from "../../extras/Button";
import { closeDialog } from "../../../redux/slice/dialogueSlice";

const CancleDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
    }
  }, [dialogueData]);

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">취소 상세</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form id="expertForm">
                <div className="row align-items-start formBody">
                  <div className="col-12">
                    <ExInput
                      type={`text`}
                      id={`reason`}
                      name={`reason`}
                      label={`취소자`}
                      placeholder={`Reason`}
                      // value={mongoId?.cancel?.reason}
                      value={"바텐더"}
                      disabled={true}
                    />
                  </div>
                  <div className="col-12">
                    <ExInput
                      type={`text`}
                      id={`Time`}
                      name={`Time`}
                      label={`사유`}
                      placeholder={`Time`}
                      // value={mongoId?.cancel?.time}
                      value={"매장 공사로 인한 영업 불가"}
                      disabled={true}
                    />
                  </div>
                  <div
                    className="col-12 d-flex"
                    style={{
                      columnGap: "20px"
                    }}
                  >
                    <div style={{ flexBasis: "calc(50% - 10px)" }}>
                      <ExInput
                        type={`text`}
                        id={`Date`}
                        name={`Date`}
                        label={`날짜`}
                        placeholder={`Date`}
                        // value={mongoId?.cancel?.date}
                        value={"2024-07-10"}
                        disabled={true}
                      />
                    </div>
                    <div
                      className="col-6 mt-auto"
                      style={{ flexBasis: "calc(50% - 10px)" }}
                    >
                      <ExInput
                        type={`text`}
                        id={`Date`}
                        name={`Date`}
                        label={``}
                        placeholder={`Date`}
                        // value={mongoId?.cancel?.date}
                        value={"08:03PM"}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancleDetails;

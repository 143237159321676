import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSalonSchedule } from "../../../redux/slice/salonSlice";
import { useLocation } from "react-router-dom";
import Title from "../../extras/Title";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import ScheduleDialogue from "./ScheduleDialogue";

const SalonSchedule = () => {
  const dummy = useMemo(
    () => [
      {
        id: 1,
        day: "월요일",
        open: "07:00 pm",
        close: "02:00 am"
      },
      {
        id: 2,
        day: "화요일",
        open: "07:00 pm",
        close: "02:00 am"
      },
      {
        id: 3,
        day: "수요일",
        open: "07:00 pm",
        close: "02:00 am"
      },
      {
        id: 4,
        day: "목요일",
        open: "07:00 pm",
        close: "02:00 am"
      },
      {
        id: 5,
        day: "금요일",
        open: "07:00 pm",
        close: "02:00 am"
      },
      {
        id: 6,
        day: "토요일",
        open: "07:00 pm",
        close: "02:00 am"
      },
      {
        id: 7,
        day: "일요일",
        open: "07:00 pm",
        close: "02:00 am"
      }
    ],
    []
  );

  const { salonSchedule } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const state = useLocation();

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(getSalonSchedule(state?.state?._id));
  }, [dispatch]);

  // useEffect(() => {
  //   setData(salonSchedule);
  // }, [salonSchedule]);

  useEffect(() => {
    setData(dummy);
  }, [dummy]);

  const handleOpenEditModal = (row) => {
    dispatch(
      openDialog({
        type: "schedule",
        data: { data: row, salonId: state?.state?._id }
      })
    );
  };

  const scheduleTable = [
    {
      Header: "요일",
      Cell: ({ row }) => (
        <span
          className="text-capitalize fw-bold"
          style={{ minWidth: "500px !important" }}
        >
          {row?.day}
        </span>
      )
    },
    {
      Header: "영업 시작 시간",
      Cell: ({ row }) => <span className="text-capitalize">{row?.open}</span>
    },

    {
      Header: "영업 종료 시간",
      Cell: ({ row }) => <span>{row?.close}</span>
    },
    {
      Header: "수정",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEditModal(row)}
        >
          수정
        </button>
      )
    }
  ];

  return (
    <div className="mainCategory">
      <Title name={`스토어바 ${state?.state?.row?.name || ""}역점 영업 시간`} />
      <p className="titleTable mb-2">사용자에게 노출될 영업 시간 입니다.</p>
      <div>
        <Table
          data={dummy}
          mapData={scheduleTable}
          serverPerPage={rowsPerPage}
          className={"tableSchedule"}
          Page={page}
        />
      </div>
      {dialogue && dialogueType === "schedule" && (
        <ScheduleDialogue setData={setData} data={data} />
      )}
    </div>
  );
};

export default SalonSchedule;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Button from "../../extras/Button";
import Table from "./Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { confirm, warning } from "../../../util/Alert";
import {
  getAllServices,
  deleteService,
  serviceStatus
} from "../../../redux/slice/serviceSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceDialogue from "./ServiceDialogue";
import Searching from "../../extras/Searching";
import { useLocation } from "react-router-dom";

const handleDataTable = (data = []) => {
  const rows = [];
  data.forEach((element, idx) => {
    const options = element.options || [];
    options.forEach((option, index) => {
      if (index === 0) {
        rows.push({
          type: element.type,
          menuName: element.menuName,
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.paymentAmount,
          optionsReserverTime: option.reserverTime,
          optionsStock: option.stock,
          id: element.id,
          rowSpan: options.length,
          isRender: true,
          index: idx + 1
        });
      } else {
        rows.push({
          type: "",
          menuName: "",
          optionsName: option.name,
          optionsPrice: option.price,
          optionsPaymentAmount: option.paymentAmount,
          optionsReserverTime: option.reserverTime,
          optionsStock: option.stock,
          isRender: false
        });
      }
    });
  });
  return rows;
};

const Service = () => {
  const dummy = useMemo(() => {
    return [
      {
        id: 1,
        type: "세트",
        menuName: "A Set",
        order: 1,
        description: "description1",
        options: [
          {
            name: "조니워커 블랙 1000mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          },
          {
            name: "조니워커 블랙 1000mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          },
          {
            name: "조니워커 블랙 1000mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          }
        ]
      },
      {
        id: 2,
        type: "세트",
        menuName: "B Set",
        order: 2,
        description: "description2",
        options: [
          {
            name: "잭다니엘 750mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          },
          {
            name: "잭다니엘 500mL",
            price: "50000원",
            paymentAmount: "40000원",
            reserverTime: "2시간",
            stock: "10 개"
          }
        ]
      },
      {
        id: 3,
        type: "단품",
        menuName: "프레첼",
        order: 3,
        description: "description3",
        options: [
          {
            name: "",
            price: "10000원",
            paymentAmount: "40000원",
            reserverTime: "",
            stock: "10 개"
          }
        ]
      },
      {
        id: 4,
        type: "단품",
        menuName: "프레첼",
        order: 4,
        description: "description4",
        options: [
          {
            name: "",
            price: "10000원",
            paymentAmount: "40000원",
            reserverTime: "",
            stock: "10 개"
          }
        ]
      }
    ];
  }, []);

  const dispatch = useDispatch();

  const { setting } = useSelector((state) => state.setting);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { service, total } = useSelector((state) => state.service);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("ALL");

  const state = useLocation();

  const payload = {
    start: page,
    limit: rowsPerPage,
    search
  };

  useEffect(() => {
    dispatch(getAllServices(payload));
  }, [page, rowsPerPage, search]);

  // useEffect(() => {
  //   setData(service);
  // }, [service]);

  useEffect(() => {
    setData(handleDataTable(dummy));
  }, [dummy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  function handleOpenEdit(id) {
    const data = dummy.find((item) => item.id === id);
    dispatch(openDialog({ type: "service", data }));
  }

  const serviceTable = [
    {
      Header: "No",
      Cell: ({ row }) => <span>{row?.index}</span>,
      isMerger: true
    },
    {
      Header: "유형",
      isMerger: true,
      Cell: ({ row }) => <span className="text-capitalize">{row?.type}</span>
    },
    {
      Header: "메뉴 이름",
      isMerger: true,
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.menuName}</span>
      )
    },
    {
      tdClass: "colOpitonName",
      Header: "옵션 이름",
      Cell: ({ row }) => (
        <span
          className="text-capitalize"
          style={{
            minWidth: "4000px"
          }}
        >
          {row?.optionsName}
        </span>
      )
    },

    {
      Header: "가격",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsPrice}</span>
      )
    },
    {
      Header: "정산금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsPaymentAmount}</span>
      )
    },
    {
      Header: "예약 가능 시간",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsReserverTime}</span>
      )
    },
    {
      Header: "재고",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.optionsStock}</span>
      )
    },
    {
      Header: "수정",
      isMerger: true,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenEdit(row.id)}
        >
          수정
        </button>
      )
    },
    {
      Header: "삭제",
      isMerger: true,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleDelete(row.id)}
        >
          삭제
        </button>
      )
    },
    {
      Header: "수정",
      isMerger: true,
      Cell: ({ row }) => (
        <div>
          {row.type === "단품" ? (
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#0c7fe9"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clip-rule="evenodd"
              />
            </svg>
          ) : null}
        </div>
      )
    }
  ];

  const handleStatus = (id) => {
    dispatch(serviceStatus(id));
  };

  const handleDelete = (id) => {
    // const data = warning("Delete");
    const data = confirm({
      title: "메뉴 삭제",
      text: "해당 메뉴를 삭제하시겠습니까?",
      confirm: "확인",
      cancle: "취소"
    });
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          // dispatch(deleteService(id));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="mainCategory">
      <Title
        name={
          state?.state?.row?.name
            ? `스토어바 ${state ? state?.state?.row?.name : ""}역점 메뉴`
            : ""
        }
      />
      <div className="row">
        <div className="col-3">
          <Button
            className={`bg-button p-10 text-white m10-bottom `}
            // bIcon={`fa-solid fa-user-plus`}
            text="메뉴 추가"
            onClick={() => {
              dispatch(openDialog({ type: "service" }));
            }}
          />
        </div>
      </div>
      <div>
        <Table
          data={data}
          mapData={serviceTable}
          serverPerPage={rowsPerPage}
          classWrapper={"mainTable tableServices"}
          Page={page}
        />
        <Pagination
          type={"server"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={total}
        />
      </div>
      {dialogue && dialogueType === "service" && (
        <ServiceDialogue setData={setData} data={data} />
      )}
    </div>
  );
};

export default Service;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Button from "../../extras/Button";
import Table from "../../extras/Table";
import Pagination from "../../extras/Pagination";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { closeDialog, openDialog } from "../../../redux/slice/dialogueSlice";
import Title from "../../extras/Title";
import { allUsers, blockUser } from "../../../redux/slice/userSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Searching from "../../extras/Searching";
import male from "../../../assets/images/male.png";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import NotificationDialog from "./NotificationDialog";
import { SelectV2 } from "../../extras/Input";
import moment from "moment";
import { confirm } from "../../../util/Alert";

export const Dummy = (count) => {
  const dummyUser = [];
  for (let i = 0; i < count; i++) {
    dummyUser.push({
      _id: i + 1,
      name: "홍길동" + i,
      nickname: `김길동${i}`,
      phone: i + "0111112222",
      point: (i + 2) * 2000,
      bookingDate: new Date("2024-12-12"),
      isBartender: i % 2 === 0 ? true : false,
      isStopped: i % 2 === 0 ? true : false
    });
  }
  return dummyUser;
};

const typeSearch = [
  { name: "전체", value: "" },
  { name: "등록", value: "isBartender" },
  { name: "미등록", value: "notBartender" }
];

export const BlackList = () => {
  const dummy = useMemo(() => Dummy(15), []);
  const dispatch = useDispatch();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { user, total } = useSelector((state) => state?.user);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("ALL");
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search
    };
    dispatch(allUsers(payload));
  }, [dispatch, page, rowsPerPage, search]);

  // useEffect(() => {
  //   setData(user);
  // }, [user]);

  useEffect(() => {
    setData(dummy);
  }, [dummy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const handleStop = async (id) => {
    try {
      const data = await confirm({
        title: "홍길동 회원의 활동을 정지 하시겠습니까?",
        text: "확인 버튼 클릭 시 해당 회원의 활동이 정지됩니다.",
        confirm: "확인",
        cancle: "취소",
        iconHtml: ""
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUnStop = async (id) => {
    try {
      const data = await confirm({
        title: "홍길동 회원의 정지를 해제 하시겠습니까?",
        text: "확인 버튼 클릭 시 해당 회원의 활동이 정지됩니다.",
        confirm: "확인",
        cancle: "취소",
        iconHtml: ""
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        // dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const userTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "이름",
      Cell: ({ row }) => <span className="text-capitalize">{row?.name}</span>
    },
    {
      Header: "닉네임",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.nickname}</span>
      )
    },
    {
      Header: "휴대폰 번호",
      Cell: ({ row }) => <span>{row?.phone}</span>
    },
    {
      Header: "보유 포인트",
      Cell: ({ row }) => <span>{row?.point || 0}p</span>
    },
    {
      Header: "예약 취소 일자",
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>
            {row?.bookingDate && moment(row.bookingDate).format("YYYY-MM-DD")}
          </span>
          <span>
            {row?.bookingDate && moment(row.bookingDate).format("YYYY-MM-DD")}
          </span>
          <span>
            {row?.bookingDate && moment(row.bookingDate).format("YYYY-MM-DD")}
          </span>
        </div>
      )
    },
    {
      Header: "프로필 상세",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleInfo(row._id)}
        >
          프로필 보기
        </button>
      )
    },
    {
      Header: "회원 정지",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ borderRadius: "8px", backgroundColor: "#E0F0FF" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => {
            if (row?.isStopped) {
              handleUnStop(row?.id);
            } else {
              handleStop(row?.id);
            }
          }}
        >
          {row?.isStopped ? "해제" : "정지"}
        </button>
      )
    }
  ];

  const handleInfo = (id) => {
    navigate("/admin/user/userProfile", {
      state: {
        id
      }
    });
  };

  const handleProfile = (row, type) => {
    dispatch(openDialog({ type: type, data: row }));
    localStorage.setItem(
      "dialog",
      JSON.stringify({ dialogue: true, type: type, data: row })
    );
  };
  const handleNotify = (id) => {
    dispatch(openDialog({ type: "notification", data: { id, type: "user" } }));
  };

  const handleStatus = (id) => {
    dispatch(blockUser(id));
  };

  const handleDownloadPDF = () => {
    const flattenedData = data.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const doc = new jsPDF({
      orientation: "landscape"
    });
    doc.text("User Data", 10, 10);
    doc.autoTable({
      head: [headers],
      body: flattenedData?.map((user) => Object?.values(user))
    });
    doc.save("user_data.pdf");
  };

  const handleDownloadCSV = () => {
    const flattenedData = data.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const csvContent = Papa.unparse({
      fields: headers,
      data: flattenedData
    });
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL?.createObjectURL(blob);
    const link = document?.createElement("a");
    link.href = url;
    link.setAttribute("download", "user_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadExcel = () => {
    const flattenedData = data?.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const ws = XLSX.utils.json_to_sheet([headers, ...flattenedData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "User Data");
    XLSX.writeFile(wb, "user_data.xlsx");
  };

  const handlePrint = () => {
    const flattenedData = data?.map((user, index) => ({
      No: index + 1,
      Name: user?.fname + " " + user?.lname,
      UniqueId: user?.uniqueId,
      MobileNo: user?.mobile,
      Email: user?.email
    }));

    const headers = ["No", "Name", "UniqueId", "MobileNo", "Email"];
    const doc = new jsPDF({
      orientation: "landscape"
    });
    doc.text("User Data", 10, 10);
    doc.autoTable({
      head: [headers],
      body: flattenedData.map((user) => Object.values(user))
    });
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  };

  const handleOpenBookings = (row) => {
    navigate("/admin/user/bookings", { state: { data: row } });
  };

  return (
    <div className="mainCategory">
      {dialogue && dialogueType === "notification" && (
        <div className="userTable">
          <NotificationDialog />
        </div>
      )}
      <div
        className="userTable"
        style={{
          display: `${dialogueType === "userHistory" ? "none" : "block"}`
        }}
      >
        <Title name="블랙 리스트" />
        <div className="betBox mb-4">
          <div className="ms-auto">
            <Button
              className="bg-button-excel text-black p5-y"
              // onClick={handleDownloadExcel}
              text={`다운로드`}
            />
          </div>
        </div>
        <div>
          <Table
            data={data}
            mapData={userTable}
            PerPage={rowsPerPage}
            Page={page}
          />
          <Pagination
            type={"server"}
            serverPage={page}
            setServerPage={setPage}
            serverPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            totalData={data.length}
          />
        </div>
      </div>
    </div>
  );
};

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */

import { openDialog } from "../../redux/slice/dialogueSlice";
import { getPayout } from "../../redux/slice/payoutSlice";
import { warning } from "../../util/Alert";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Title from "../extras/Title";
import Button from "../extras/Button";
import Table from "../extras/Table";
import Pagination from "../extras/Pagination";

import { getSalary, payment } from "../../redux/slice/salarySlice";
import BonusPenaltyDialog from "./BonusPenaltyDialog";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Analytics from "../extras/Analytics";
import DateRange from "../extras/DateRange";
import PaymentDetailModal from "./PaymentDetailModal";
import BatchModal from "./BatchModal";
import SettlementInforModal from "./SettlementInforModal";

const Dummy = (count) => {
  const dummySettlement = [];
  for (let i = 0; i < count; i++) {
    dummySettlement.push({
      _id: i + 1, // Assuming _id is a unique identifier
      name: "Dummy" + i,
      phone: i + "0111112222",
      branch: i < 5 ? "A" : "B",
      reservationNumber: "#" + i + 2,
      resvervationDate: new Date("2024-02-22"),
      depositAccountName: "Kookmin Bank",
      depositAccountNumber: "000000-00-000000",
      amountSubject: "20000",
      amount: "19333",
      status: i === 0 ? "미승인" : i === 1 ? "승인" : "완료",
      processDate: i === 0 ? new Date("2204-07-20") : ""
    });
  }
  return dummySettlement;
};

const typeSearch = [
  {
    value: "",
    text: "전체"
  },
  {
    value: "Unapproved",
    text: "미승인"
  },
  {
    value: "Rejected",
    text: "거절"
  },
  {
    value: "Approved",
    text: "승인"
  },
  {
    value: "Completed",
    text: "완료"
  }
];

export const Searching = (props) => {
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [string, setString] = useState("");
  const {
    data,
    setData,
    type,
    typeSearch,
    serverSearchingType,
    serverSearchingValue,
    serverSearchingString,
    button
  } = props;

  const handleSearch = (value) => {
    let searchValue = search ? search : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingValue(searchValue);
    }
  };

  const handleSearchType = (value) => {
    let searchValue = searchType ? searchType : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingType(searchValue);
    }
  };

  const handleSearchString = (value) => {
    let searchValue = search ? search : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingString(string);
    }
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <div className="d-flex flex-column inputData">
            <label>정산 상태</label>
            <select
              value={searchType}
              className="inputTypeSearch"
              style={{
                height: "40px",
                marginTop: "0",
                width: "110px",
                minWidth: "110px"
              }}
              placeholder="선택"
              onChange={
                button
                  ? (e) => setSearchType(e.target.value)
                  : (e) => handleSearchType(e.target.value)
              }
            >
              {typeSearch.map((item) => (
                <option value={item.value} key={item.text}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex flex-column inputData">
            <label>검색</label>
            <input
              type="search"
              id="search"
              // placeholder="Searching for..."
              className="bg-none m0-top w-100"
              style={{ fontWeight: "500", height: "40px" }}
              onChange={
                button
                  ? (e) => setSearch(e.target.value)
                  : (e) => handleSearch(e.target.value)
              }
            />
          </div>
          <div className="d-flex flex-column inputData">
            <label>&nbsp;</label>
            <input
              type="search"
              id="search"
              // placeholder="Searching for..."
              className="bg-none m0-top w-100"
              style={{ fontWeight: "500", height: "40px" }}
              onChange={
                button
                  ? (e) => setSearch(e.target.value)
                  : (e) => handleSearch(e.target.value)
              }
            />
          </div>
          <button
            className="py-1 bg-button-search mt-auto"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "150px"
            }}
            // onClick={() => handleOpenBookings(row)}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

const StoreSettlementInfo = () => {
  const dummySettlement = useMemo(() => Dummy(15), []);
  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

    const startOfMonth = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}-01`;
    const lastDay = new Date(currentYear, currentMonth, 0).getDate();
    const endOfMonth = `${currentYear}-${currentMonth
      .toString()
      .padStart(2, "0")}-${lastDay}`;

    return { startOfMonth, endOfMonth };
  };

  const state = useLocation();
  const { salary } = useSelector((state) => state.salary);
  const [data, setData] = useState([]);

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const { setting } = useSelector((state) => state.setting);
  const { startOfMonth, endOfMonth } = getCurrentMonthDates();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [string, setString] = useState("");
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);
  const navigate = useNavigate();
  const thisMonth = new Date();
  thisMonth.setDate(1);
  const [selectedDate, setSelectedDate] = useState(thisMonth);
  const dispatch = useDispatch();

  useEffect(() => {
    const formattedDate = moment(selectedDate, "YYYY-MM").format("YYYY-MM");
    const payload = {
      start: page,
      limit: rowsPerPage,
      startDate,
      endDate,
      type
    };
    dispatch(getSalary(payload));
  }, [page, rowsPerPage, type, startDate, endDate]);

  // useEffect(() => {
  //   setData(salary);
  // }, [salary]);

  useEffect(() => {
    setData(dummySettlement);
  }, [dummySettlement]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const mapData = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },

    {
      Header: "이름",
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#CDE7FF", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          // onClick={() => handleInfo(row?._id)}
        >
          {row?.name}
        </button>
        // <div className="d-flex"></div>
      )
    },
    ...(!state?.state?.row?.name
      ? [
          {
            Header: "매장",
            Cell: ({ row }) => (
              <button
                className="py-1 text-decoration-underline"
                // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
                style={{ backgroundColor: "#fff" }}
                onClick={() => handleOpenPaymentModal(row?._id)}
              >
                {row?.branch}
              </button>
            )
          }
        ]
      : []),
    {
      Header: "전화번호",
      Cell: ({ row }) => <span className="text-capitalize">{row?.phone}</span>
    },
    {
      Header: "예약 번호",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.reservationNumber}</span>
      )
    },
    {
      Header: `예약일`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.resvervationDate &&
            moment(row.resvervationDate).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: `입금 계좌`,
      Cell: ({ row }) => (
        <div className="text-center d-flex flex-column">
          <span className="text-capitalize">{row?.depositAccountName}</span>
          <span className="text-capitalize">{row?.depositAccountNumber}</span>
        </div>
      )
    },

    {
      Header: "정산 대상 금액",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.amountSubject}</span>
      )
    },
    {
      Header: `정산 금액`,
      Cell: ({ row }) => <span className="text-capitalize">{row?.amount}</span>
    },
    {
      Header: `정산 여부`,
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>{row?.status}</span>
          {(row?.status === "미승인" || row?.status === "승인") && (
            <div className="d-flex align-items-center justify-content-center gap-2">
              {row?.status === "미승인" && (
                <button
                  className="py-1"
                  style={{ backgroundColor: "#e0f0ff", borderRadius: "8px" }}
                  // onClick={() => handleDelete(row?._id)}
                >
                  승인
                </button>
              )}
              <button
                className="py-1"
                style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
                // onClick={() => handleDelete(row?._id)}
              >
                완료
              </button>
            </div>
          )}
        </div>
      )
    },
    {
      Header: `처리 일자`,
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.processDate
            ? moment(row.processDate).format("YYYY-MM-DD")
            : "-"}
        </span>
      )
    },
    {
      Header: `결제 상세`,
      Cell: ({ row }) => (
        <button
          className="py-1 text-decoration-underline"
          // style={{ backgroundColor: "#FFF1F1", borderRadius: "8px" }}
          style={{ backgroundColor: "#fff" }}
          onClick={() => handleOpenPaymentModal(row?._id)}
        >
          결제상세
        </button>
      )
    }
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePayment = (row) => {
    const payload = {
      settlementId: row?._id
    };
    dispatch(payment(payload));
  };

  const handleEarning = (row) => {
    // navigate("/admin/salon/income", {
    //   state: {
    //     row,
    //   },
    // });
  };

  const handleInfoSettlement = (row) => {
    // navigate("/admin/salon/particularSettlement", {
    //   state: {
    //     row,
    //   },
    // });
  };

  const handleFilterName = (filteredData) => {
    if (typeof filteredData === "string") {
      setName(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterString = (filteredData) => {
    if (typeof filteredData === "string") {
      setString(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleOpenBatchApproveModal = () => {
    dispatch(openDialog({ type: "batchApprove", data: null }));
  };

  const handleOpenBatchCompleteModal = () => {
    dispatch(openDialog({ type: "batchComplete", data: null }));
  };

  const handleOpenPaymentModal = (id) => {
    dispatch(openDialog({ type: "paymentDetail", data: id }));
  };

  const handleOpenInfoModal = () => {
    dispatch(openDialog({ type: "settlementInfo", data: null }));
  };

  return (
    <div className="mainCategory">
      {dialogue && dialogueType === "paymentDetail" && (
        <PaymentDetailModal allStore={!!!state?.state?.row?.name} />
      )}
      {dialogue && dialogueType === "batchApprove" && (
        <BatchModal title="일괄 승인" subTitle="일괄 승인할 금액 목록입니다." />
      )}
      {dialogue && dialogueType === "batchComplete" && (
        <BatchModal
          title="일괄 완료"
          subTitle="일괄 완료처리할 금액 목록입니다."
        />
      )}
      {dialogue && dialogueType === "settlementInfo" && (
        <SettlementInforModal />
      )}
      <Title
        name={
          state?.state?.row?.name
            ? `스토어바 ${state?.state?.row?.name || ""}역점 정산 관리`
            : "예약 정산 관리"
        }
      />
      <div className="d-flex justify-content-between mb-2">
        <Searching
          type={`server`}
          data={data}
          typeSearch={typeSearch}
          setData={setData}
          column={mapData}
          serverSearchingType={handleFilterDataType}
          serverSearchingValue={handleFilterName}
          serverSearchingString={handleFilterString}
        />
        <button
          className="py-1 bg-button-search mt-auto"
          style={{
            borderRadius: "8px",
            height: "40px",
            width: "150px"
          }}
          // onClick={() => handleOpenBookings(row)}
        >
          다운로드
        </button>
      </div>
      <div className="mb-2">
        <DateRange
          serverSearching={({ startDate, endDate }) => {
            // setStartDate(startDate);
            // setEndDate(endDate);
          }}
        />
      </div>
      <div className="mb-4 inputData">
        <label>리스트 내 총 정산 금액</label>
        <div className="d-flex align-items-center gap-4">
          <div
            style={{ height: "40px" }}
            className="d-flex align-items-center justify-content-center px-4 bg-button-search rounded"
          >
            10,000,000 원
          </div>
          <button
            className="py-1 bg-button-search mt-auto rounded"
            style={{
              height: "40px",
              width: "150px"
            }}
            onClick={() => handleOpenBatchApproveModal()}
          >
            일괄 승인
          </button>
          <button
            className="py-1 bg-button-search mt-auto rounded"
            style={{
              height: "40px",
              width: "150px"
            }}
            onClick={() => handleOpenBatchCompleteModal()}
          >
            일괄 완료
          </button>
          <button
            className="py-1 bg-button-search mt-auto rounded"
            style={{
              height: "40px",
              width: "190px"
            }}
            onClick={() => handleOpenInfoModal()}
          >
            계좌별 정산 금액
          </button>
        </div>
      </div>
      <div>
        <Table
          data={data}
          mapData={mapData}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
    </div>
  );
};

export default StoreSettlementInfo;

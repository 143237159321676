import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";

const AddressModal = ({ handleSelectAddress }) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData);
    }
  }, [dialogueData]);

  const handleFilterData = (filteredData) => {
    setSearch(filteredData);
  };

  return (
    <div className="dialog">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-md-4 col-11">
            <div class="mainDiaogBox position-relative">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h4 className="text-theme m0">우편번호 검색</h4>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <div className="inputData d-flex">
                    <input
                      type="search"
                      id="search"
                      placeholder="예) 판교역로 166, 분당 주공, 백현동 532"
                      className="bg-none m0-top"
                      style={{ fontWeight: "500", height: "48px" }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div
                      className="bg-theme p15-x midBox searchIcon"
                      style={{ height: "48px" }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 9C20.6421 9 24 12.3579 24 16.5M24.9882 24.9823L31.5 31.5M28.5 16.5C28.5 23.1275 23.1275 28.5 16.5 28.5C9.87258 28.5 4.5 23.1275 4.5 16.5C4.5 9.87258 9.87258 4.5 16.5 4.5C23.1275 4.5 28.5 9.87258 28.5 16.5Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  {search && (
                    <div
                      style={{
                        position: "absolute",
                        top: "120px",
                        left: 0,
                        right: 0,
                        margin: "0 auto",
                        width: "90%",
                        borderRadius: "12px",
                        backgroundColor: "#fff",
                        padding: "10px",
                        fontSize: "14px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                      }}
                    >
                      address
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginTop: "20px", fontSize: "16px" }}>
                <p className="font-bold text-lg" style={{ fontSize: "20px" }}>
                  tip
                </p>
                <p>
                  아래와 같은 조합으로 검색을 하시면 더욱 정확한 결과가
                  검색됩니다.
                </p>
                <p style={{ marginTop: "10px" }}>도로명 + 건물번호</p>
                <p style={{ color: "#00ace7" }}>
                  예) 판교역로 166, 제주 첨단로 242
                </p>
                <p style={{ marginTop: "5px" }}>지역명(동/리) + 번지</p>
                <p style={{ color: "#00ace7" }}>
                  예) 백현동 532, 제주 영평동 2181
                </p>
                <p style={{ marginTop: "5px" }}>
                  지역명(동/리) + 건물명(아파트명)
                </p>
                <p style={{ color: "#00ace7" }}>
                  예) 분당 주공, 연수동 주공3차
                </p>
                <p style={{ marginTop: "5px" }}>사서함명 + 번호</p>
                <p style={{ color: "#00ace7" }}>예) 분당우체국사서함 1~100</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddressModal;

import React from "react";
import { ExInput, Textarea } from "../../extras/Input";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extras/Button";

const ComplainDialog = () => {
  const dispatch = useDispatch();
  const { dialogueData } = useSelector((state) => state.dialogue);

  return (
    <div className="dialog focusNone">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-md-6 col-11">
            <div class="mainDiaogBox">
              <div class="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-theme m0">이의 상세</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="row align-items-start formBody">
                <div className="col-12">
                  <ExInput
                    type={`text`}
                    label={"신청자"}
                    value={"홍길동"}
                    readOnly={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"이의신청 날짜"}
                    value={"2024-07-10"}
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"이의 처리 날짜"}
                    value={"-"}
                    readOnly={true}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"정산 처리 날짜"}
                    value={"2024-07-10"}
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"처리 결과"}
                    value={"거절"}
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"정산 대상 금액"}
                    value={"20000"}
                    readOnly={true}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <ExInput
                    type={`text`}
                    label={"실제 입금액"}
                    value={"18000"}
                    readOnly={true}
                  />
                </div>
                <div className="col-12">
                  <Textarea
                    // value={dialogueData?.details}
                    value={
                      "정산 신청을 했는데, 아직 정산이 되고 있지 않습니다."
                    }
                    label={`이의 제기 메세지`}
                    placeholder={`Details`}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="inputData">
                <label>이의 처리</label>
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <Button
                    className={`text-black fw-bold border`}
                    text={`정산 완료`}
                    type={`button`}
                    style={{ backgroundColor: "#fff2cc" }}
                    // onClick={() => dispatch(closeDialog())}
                  />
                  <Button
                    type={`submit`}
                    className={`text-black m10-left border fw-bold`}
                    style={{ backgroundColor: "#fff" }}
                    text={`이의 거절`}
                    // onClick={(e) => handleSubmit(e)}
                  />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => dispatch(closeDialog())}
                />
                <Button
                  type={`submit`}
                  className={` text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`저장`}
                  // onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplainDialog;

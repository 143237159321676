import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../extras/ToggleSwitch";
import { confirm, warning } from "../../../util/Alert";
import Title from "../../extras/Title";
import Pagination from "../../extras/Pagination";
import {
  activesalon,
  getAllSalons,
  salonDelete
} from "../../../redux/slice/salonSlice";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "./../../extras/Table";
import Button from "../../extras/Button";
import SendModal from "./SendModal";

export const Dummy = (count) => {
  const dummyNotifcation = [];
  for (let i = 0; i < count; i++) {
    dummyNotifcation.push({
      _id: i + 1,
      title: "Dummy" + i,
      detail:
        i +
        `바텐더 이용 안내 사항 바텐더 이용 안내 사항바텐더 이용 안내 사항바텐더 이용 안내 사항바텐더 이용 안내 사항`,
      date: new Date("2024-09-11"),
      type: i < 2 ? "바텐더" : i < 4 ? "사용자" : "전체",
      number: (i + 1) * 13
    });
  }
  return dummyNotifcation;
};

const typeSearch = [
  {
    value: "title",
    text: "제목"
  },
  {
    value: "detail",
    text: "내용"
  }
];

export const SearchingWithSelect = (props) => {
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const {
    data,
    setData,
    type,
    typeSearch,
    serverSearchingType,
    serverSearchingValue,
    button
  } = props;

  const handleSearch = (value) => {
    let searchValue = search ? search : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingValue(searchValue);
    }
  };

  const handleSearchType = (value) => {
    let searchValue = searchType ? searchType : value;
    if (type === "client") {
      if (searchValue) {
        const filteredData = data.filter((item) => {
          return Object.keys(item).some((key) => {
            if (key === "_id" || key === "updatedAt" || key === "createdAt") {
              return false;
            }
            const itemValue = item[key];
            if (typeof itemValue === "string") {
              return itemValue.toLowerCase().indexOf(searchValue) > -1;
            } else if (typeof itemValue === "number") {
              return itemValue.toString().indexOf(searchValue) > -1;
            }
            return false;
          });
        });
        setData(filteredData);
      } else {
        setData(data);
      }
    } else {
      serverSearchingType(searchValue);
    }
  };

  return (
    <>
      <div className="">
        <div className="inputData inputDataSearch d-flex gap-4">
          <select
            value={searchType}
            className="inputTypeSearch"
            style={{
              height: "40px",
              marginTop: "0",
              width: "130px",
              minWidth: "130px"
            }}
            placeholder="선택"
            onChange={
              button
                ? (e) => setSearchType(e.target.value)
                : (e) => handleSearchType(e.target.value)
            }
          >
            <option value={""} disabled>
              선택
            </option>
            {typeSearch.map((item) => (
              <option value={item.value} key={item.text}>
                {item.text}
              </option>
            ))}
          </select>
          <input
            type="search"
            id="search"
            // placeholder="Searching for..."
            className="bg-none m0-top w-100"
            style={{ fontWeight: "500", height: "40px" }}
            onChange={
              button
                ? (e) => setSearch(e.target.value)
                : (e) => handleSearch(e.target.value)
            }
          />
          <button
            className="py-1 bg-button-search"
            style={{
              borderRadius: "8px",
              height: "40px",
              width: "100px",
              minWidth: "100px"
            }}
            // onClick={() => handleOpenBookings(row)}
          >
            검색
          </button>
        </div>
      </div>
    </>
  );
};

export const NotificationList = () => {
  const dummy = useMemo(() => Dummy(15), []);
  const [data, setData] = useState([]);

  const { salon } = useSelector((state) => state.salon);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handleFilterDataType = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearchType(filteredData);
    } else {
      setData(filteredData);
    }
  };

  useEffect(() => {
    const payload = {
      start: page,
      limit: rowsPerPage,
      search
    };
    dispatch(getAllSalons(payload));
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setData(dummy);
  }, [dummy]);

  function openImage(imageUrl) {
    // Open the image in a new tab or window
    window.open(imageUrl, "_blank");
  }

  const handleInfo = (id) => {
    // navigate("/admin/salon/salonProfile", {
    //   state: {
    //     id
    //   }
    // });
  };

  const handleOpenMenu = (row) => {
    navigate("/admin/store/menu", {
      state: {
        row
      }
    });
  };

  const salonTable = [
    {
      Header: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "발송일",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.date && moment(row.date).format("YYYY-MM-DD")}
        </span>
      )
    },
    {
      Header: "제목",
      Cell: ({ row }) => <span>{row?.title}</span>
    },
    {
      Header: "내용",
      tdClass: "colContentCoupon",
      Cell: ({ row }) => <span className="truncate-line-2">{row?.detail}</span>
    },
    {
      Header: "회원 타입",
      Cell: ({ row }) => <span className="">{row?.type}</span>
    },
    {
      Header: "발송 회원수",
      Cell: ({ row }) => <span className="">{row?.number}</span>
    }
  ];

  const handleAddSalon = (row) => {
    navigate("/admin/store/addStore", {
      state: {
        row
      }
    });
  };

  const handleEarning = (row) => {
    navigate("/admin/store/settlement", {
      state: {
        row
      }
    });
  };

  const handleDelete = async (id) => {
    try {
      const data = await confirm({
        title: "삭제 하시겠습니까?",
        text: "삭제 버튼 클릭 시 되돌릴 수 없습니다.",
        confirm: "삭제",
        cancle: "취소"
      });
      const yes = data?.isConfirmed;
      console.log("yes", yes);
      if (yes) {
        dispatch(salonDelete(id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenAdd = () => {
    dispatch(openDialog({ type: "sendModal", data: null }));
  };

  const handleOpenIssue = (row) => {
    dispatch(openDialog({ type: "couponIssue", data: row }));
  };

  return (
    <div className="userTable">
      <Title name="PUSH 알림 설정" />

      <div className="betBox mb-3">
        <div className="col-md-8 col-lg-7 inputData">
          <label>검색</label>
          <SearchingWithSelect
            type={`server`}
            data={dummy}
            typeSearch={typeSearch}
            setData={setData}
            column={salonTable}
            serverSearchingType={handleFilterData}
            serverSearchingValue={handleFilterDataType}
            button
          />
        </div>
        <Button
          className={`d-flex align-items-center bg-button p-10 text-white m10-bottom ms-auto mt-auto`}
          style={{ marginLeft: "20px", height: "40px" }}
          text={`알림 발송`}
          // bIcon={`fa-solid fa-user-plus`}
          onClick={() => handleOpenAdd()}
        />
      </div>
      <div>
        <Table
          data={dummy}
          mapData={salonTable}
          PerPage={rowsPerPage}
          Page={page}
          type={"client"}
        />
        <Pagination
          type={"client"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalData={data?.length}
        />
      </div>
      {dialogue && dialogueType === "sendModal" && (
        <SendModal setData={setData} data={data} />
      )}
    </div>
  );
};

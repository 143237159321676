import React, { useEffect, useState } from "react";
import { closeDialog, openDialog } from "../../../redux/slice/dialogueSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../extras/Button";
import Input, { ExInput, Textarea } from "../../extras/Input";

import { addSalon, updateSalon } from "../../../redux/slice/salonSlice";
import ReactDropzone from "react-dropzone";
import Title from "../../extras/Title";
import { useLocation, useNavigate } from "react-router-dom";
import GenderGroup from "../../extras/GenderGroup";
import AddressModal from "./AddressModal";

const AddSalon = () => {
  const { state } = useLocation();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [numberTables, setNumberTables] = useState();
  const [genderReser, setGenderReser] = useState("other");
  const [genderBatender, setGenderBatender] = useState("other");
  const [youtube, setYoutube] = useState();
  const [naver, setNaver] = useState();
  const [instagram, setInstagram] = useState();
  const [about, setAbout] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [landMark, setLandMark] = useState();
  const [city, setCity] = useState();
  const [states, setStates] = useState();
  const [country, setCountry] = useState();
  const [mainImage, setMainImage] = useState([]);
  const [images, setImages] = useState([]);
  const [imagePath, setImagePath] = useState();
  const [mobile, setMobile] = useState();
  const [platformFee, setPlatformFee] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState({
    name: "",
    email: "",
    address: "",
    landMark: "",
    city: "",
    state: "",
    country: "",
    images: "",
    mobile: "",
    platformFee: "",
    latitude: "",
    longitude: "",
    password: "",
    about: "",
    numberTables: "",
    youtube: "",
    naver: "",
    instagram: ""
  });

  useEffect(() => {
    if (state) {
      setName(state?.row?.name);
      setNumberTables(state?.row?.numberTables);
      setAddress(state?.row?.address);
      setMobile(state?.row?.phone);
      setMainImage(state?.row?.mainImage || []);
      setImages(state?.row?.image || []);
      setYoutube(state?.row?.youtube);
      setInstagram(state?.row?.instagram);
      setNaver(state?.row?.naver);
    }
  }, [state]);

  const handleImage = (e) => {
    setError((prevErrors) => ({
      ...prevErrors,
      mainImage: ""
    }));
    setMainImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const onPreviewDrop = (files) => {
    setError({ ...error, image: "" });
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setImages(files);
  };

  const onPreviewMainDrop = (files) => {
    setError({ ...error, mainImage: "" });
    files.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setMainImage(files);
  };

  const removeImage = (file) => {
    if (file?.preview) {
      const image = images.filter((ele) => {
        return ele?.preview !== file?.preview;
      });
      setImages(image);
    } else {
      const image = images.filter((ele) => {
        return ele !== file;
      });
      setImages(image);
    }
  };

  const removeMainImage = () => {
    setMainImage([]);
  };

  const handleSubmit = (e) => {
    if (
      !name ||
      !email ||
      !about ||
      !password ||
      !mobile ||
      !address ||
      !landMark ||
      !city ||
      !states ||
      !country ||
      !latitude ||
      !longitude ||
      !images ||
      images?.length === 0 ||
      images?.length > 10 ||
      !platformFee
    ) {
      let error = {};
      if (!name) error.name = "Name is required";
      if (!about) error.about = "About is required";
      if (!email) error.email = "Email is required";
      if (!password) error.password = "Password is required";
      if (!platformFee) error.platformFee = "Plat form fee is required";
      if (!mobile) error.mobile = "Mobile number is required";
      if (!address) error.address = "Address is required";
      if (!landMark) error.landMark = "Land mark is required";
      if (!city) error.city = "City is required";
      if (!states) error.state = "State is required";
      if (!country) error.country = "Country is required";
      if (!latitude) error.latitude = "Latitude is required";
      if (!longitude) error.longitude = "Longitude is required";
      if (!images) error.images = "Images is required";
      if (images?.length === 0) error.images = "Images is required";
      if (images?.length > 10) error.images = "Select max 10 images";

      return setError({ ...error });
    } else {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("landMark", landMark);
      formData.append("city", city);
      formData.append("state", states);
      formData.append("country", country);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("platformFee", platformFee);
      formData.append("mobile", mobile);
      formData.append("password", password);
      formData.append("about", about);
      if (state?.row) {
        formData.append("mainImage", mainImage);
      }

      for (let index = 0; index < images?.length; index++) {
        formData.append("image", images[index]);
      }

      if (state?.row) {
        let payload = { salonId: state?.row?._id, formData: formData };
        dispatch(updateSalon(payload));
        navigate(-1);
      } else {
        dispatch(addSalon(formData));
        navigate(-1);
      }
    }
  };

  const handleOpenAddressModal = (id) => {
    dispatch(openDialog({ type: "addressModal", data: address }));
  };

  return (
    <div className="p-3">
      <Title name={state?.row ? `매장 수정` : "매장 추가"} />
      <div className="card">
        {dialogue && dialogueType === "addressModal" && (
          <div className="userTable">
            <AddressModal handleSelectAddress={(value) => setAddress(value)} />
          </div>
        )}
        <div className="card-body">
          <div class="">
            <div className="row align-items-start formBody">
              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`name`}
                  name={`name`}
                  value={name}
                  label={`매장 이름`}
                  placeholder={`매장 이름을 입력하세요.`}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: ` Name is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        name: ""
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`number`}
                  value={mobile}
                  id={`mono`}
                  name={`mobile`}
                  label={`대표 전화번호`}
                  minLength={6}
                  maxLength={13}
                  placeholder={`전화번호를 입력하세요.`}
                  errorMessage={error.mobile && error.mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        mobile: `Mobile number is required`
                      });
                    } else if (
                      e.target.value.length < 6 ||
                      e.target.value.length > 13
                    ) {
                      return setError({
                        ...error,
                        mobile: "Mobile number must be 6 to 13 digits"
                      });
                    } else {
                      return setError({
                        ...error,
                        mobile: ""
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`phone`}
                  name={`phone`}
                  value={numberTables}
                  label={`테이블 수`}
                  placeholder={`테이블 수를 입력하세요`}
                  errorMessage={error.numberTables && error.numberTables}
                  onChange={(e) => {
                    setNumberTables(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        numberTables: `Number of Tables is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        numberTables: ""
                      });
                    }
                  }}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-6 d-flex align-items-end">
                <ExInput
                  type={`text`}
                  id={`address`}
                  name={`Address`}
                  value={address}
                  label={`매장 주소`}
                  disabled={true}
                  errorMessage={error.address && error.address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        address: `Address is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        address: ""
                      });
                    }
                  }}
                />
                <Button
                  className={`bg-button-excel text-black font-bold`}
                  style={{
                    marginBottom: "5px",
                    padding: "8px 12px",
                    marginLeft: "20px",
                    minWidth: "70px"
                  }}
                  text={`검색`}
                  onClick={() => handleOpenAddressModal()}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                <div style={{ marginRight: "40px" }}>
                  <GenderGroup
                    id={`genderReser`}
                    label={`예약 가능 성별`}
                    gender={genderReser}
                    onChange={(value) => setGenderReser(value)}
                  />
                </div>
                <GenderGroup
                  id={`genderBatender`}
                  label={`근무 가능 성별`}
                  gender={genderBatender}
                  onChange={(value) => setGenderBatender(value)}
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`youtube`}
                  name={`youtube`}
                  value={youtube}
                  label={`유튜브`}
                  placeholder={`https://youtube.com`}
                  errorMessage={error.youtube && error.youtube}
                  onChange={(e) => {
                    setYoutube(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        youtube: `Youtube is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        youtube: ""
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`instagram`}
                  name={`instagram`}
                  value={instagram}
                  label={`인스타그램`}
                  placeholder={`https://www.instagram.com`}
                  errorMessage={error.instagram && error.instagram}
                  onChange={(e) => {
                    setInstagram(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        instagram: `instagram is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        instagram: ""
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <ExInput
                  type={`text`}
                  id={`naver`}
                  name={`naver`}
                  value={naver}
                  label={`네이버 블로그`}
                  placeholder={`https://blog.naver.com`}
                  errorMessage={error.naver && error.naver}
                  onChange={(e) => {
                    setNaver(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        naver: `Naver is required`
                      });
                    } else {
                      return setError({
                        ...error,
                        naver: ""
                      });
                    }
                  }}
                />
              </div>

              {/* {state?.row && (
                <div className="col-12 d-flex">
                  <ExInput
                    label={`Main Image`}
                    id={`mainImage`}
                    type={`file`}
                    onChange={(e) => handleImage(e)}
                    errorMessage={error.mainImage && error.mainImage}
                    accept={"image/*"}
                  />
                  <img
                    src={imagePath}
                    alt=""
                    draggable="false"
                    className={`${
                      (!imagePath || imagePath === "") && "d-none"
                    } ms-4`}
                    data-class={`showImage`}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px"
                    }}
                  />
                </div>
              )} */}
              <div className="col-12">
                <div class="inputData text  flex-row justify-content-start text-start">
                  <label for="latitude" class="false ">
                    대표 사진 선택 ({mainImage?.length || 0}/1)
                  </label>

                  <ReactDropzone
                    onDrop={(acceptedFiles) => onPreviewMainDrop(acceptedFiles)}
                    accept="image/*"
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            style={{
                              height: 130,
                              width: 130,
                              border: "2px dashed gray",
                              textAlign: "center",
                              marginTop: "10px"
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ paddingTop: 30, fontSize: 70 }}
                            ></i>
                          </div>
                        </div>
                      </section>
                    )}
                  </ReactDropzone>
                </div>
              </div>
              <div className="col-12 d-flex">
                {mainImage?.length > 0 && (
                  <>
                    {mainImage.map((file, index) => {
                      return (
                        <div key={index}>
                          <img
                            height="60px"
                            width="60px"
                            alt="representative"
                            src={file?.preview ? file?.preview : file}
                            style={{
                              height: "100px",
                              width: "100px",
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              float: "left",
                              marginRight: 15
                            }}
                          />
                          <div
                            className="img-container"
                            style={{
                              display: "inline",
                              position: "relative",
                              float: "left"
                            }}
                          >
                            <i
                              className="fas fa-times-circle text-danger"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "4px",
                                cursor: "pointer"
                              }}
                              onClick={() => removeMainImage()}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div className="col-12">
                <div class="inputData text  flex-row justify-content-start text-start">
                  <label for="latitude" class="false ">
                    사진 선택 ({images?.length || 0}/10)
                  </label>

                  <ReactDropzone
                    onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                    accept="image/*"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div
                            style={{
                              height: 130,
                              width: 130,
                              border: "2px dashed gray",
                              textAlign: "center",
                              marginTop: "10px"
                            }}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ paddingTop: 30, fontSize: 70 }}
                            ></i>
                          </div>
                        </div>
                      </section>
                    )}
                  </ReactDropzone>
                </div>
              </div>
              <div className="col-12 d-flex">
                {images?.length > 0 && (
                  <>
                    {images.map((file, index) => {
                      return (
                        <div key={index}>
                          <img
                            height="60px"
                            width="60px"
                            alt="representative"
                            src={file?.preview ? file?.preview : file}
                            style={{
                              height: "100px",
                              width: "100px",
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              float: "left",
                              marginRight: 15
                            }}
                          />
                          <div
                            className="img-container"
                            style={{
                              display: "inline",
                              position: "relative",
                              float: "left"
                            }}
                          >
                            <i
                              className="fas fa-times-circle text-danger"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "4px",
                                cursor: "pointer"
                              }}
                              onClick={() => removeImage(file)}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>

            <div className="row  formFooter">
              <div className="col-12 text-end m0">
                <Button
                  className={`bg-gray text-light`}
                  text={`취소`}
                  type={`button`}
                  onClick={() => navigate(-1)}
                />
                <Button
                  type={`submit`}
                  className={` text-white m10-left`}
                  style={{ backgroundColor: "#1ebc1e" }}
                  text={`등록`}
                  onClick={(e) => handleSubmit(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalon;
